import { forwardRef } from 'react'
import Link from 'next/link'

const CustomLink = forwardRef(({ options, ...props }, ref) => {
	return (
		<Link scroll={props.scroll} shallow={props.shallow} href={props.url}>
			<a
				aria-label={props.ariaLabel}
                rel={props.rel}
				ref={ref}
				onMouseOver={props.onMouseOver}
				onMouseOut={props.onMouseOut}
				onMouseEnter={props.onMouseEnter}
				onMouseLeave={props.onMouseLeave}
				onClick={props.onClick}
				className={props.className}
				id={props.id && props.id}
				{...options}
			>
				{props.content || props.children}
			</a>
		</Link>
	)
})

export default CustomLink
