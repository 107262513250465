import React, { useImperativeHandle, useState, useCallback, forwardRef, useRef, useEffect } from 'react'

//* Components
import { Form } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import Text from 'components/common/Text'
import Icon from 'components/common/Icon'

const FormContainer = forwardRef(({ layout, className, resetFields = true, ...props }, ref) => {
    //! Form
    const [form] = useForm()

    //! Send Form as Ref
    useImperativeHandle(ref, () => {
        return form
    })

    //! States
    const [error, setError] = useState()

    //! On Value Change
    const onValuesChange = (newVal, vals) => {
        props.onChange && props.onChange(newVal, vals, form)
    }

    //! On Finish
    const onFinish = useCallback(
        (values) => {
            form.setFields([])
            setError('')

            props
                .onFinish(values)
                .then((res) => {
                    resetFields && form.resetFields()
                    Promise.resolve(res)
                })
                .catch((err) => {
                    console.error(err)
                    if (err?.data) {
                        const formVals = []

                        if (typeof err.data.message === 'string') {
                            props.setError ? props.setError(err.data.message) : setError(err.data.message)
                        }

                        for (let key in values) {
                            formVals.push({
                                name: key,
                                value: values[key],
                                errors: [err.data.errors?.[key]]?.[0],
                            })
                        }
                        form.setFields(formVals)
                    }

                    Promise.reject(err?.data)
                })
        },
        [error, props.onFinish]
    )

    const formRef = useRef()

    return (
        <div ref={formRef}>
            {error && (
                <div className='textAlignCenter globalErrorInfoWrap'>
                    {' '}
                    <Text tag={'p'} className={'geoReg errorRed'} text={error} />{' '}
                </div>
            )}
            <Form
                layout={layout || ''}
                initialValues={props.initialValues}
                onFinish={onFinish}
                onValuesChange={onValuesChange}
                form={form}
                className={className || ''}
            >
                {props.children}
            </Form>
        </div>
    )
})

export default FormContainer
