import styled from 'styled-components'

const ContainerStyle = styled.section.attrs({})`
	padding-left: var(--containerPadding);
	padding-right: var(--containerPadding);
	margin-right: auto;
	margin-left: auto;
	width: 100%;
	
	&.home {
		padding:0 var(--containerPaddingHome);
	}

	&.large {
		padding-left: var(--containerPaddingLarge);
		padding-right: var(--containerPaddingLarge);

	}

	&.containerPaddBottom{
		margin-bottom: var(--containerMarginBottom);
	}

	&.no-padding {
		--containerPadding: 0;
	}

`
export default ContainerStyle
