import styled from 'styled-components'

const LogoStyle = styled.div`
  --headerLogoSize:var(--sp9x);
  --footerLogoSize: var(--sp9x);

  &.Logo {
    width: var(--headerLogoSize);

    a {
      width: 100%;
    }

    .imageCont {
      width: 100% !important;
      padding-top: 100%;
    }


    &.footerLogo {
      width: var(--footerLogoSize);
    }
  }

  @media only screen and (max-width: ${props => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${props => props.theme.mediaQuery.DesktopSizeM}) {
    --headerLogoSize: var(--sp7x);
    --footerLogoSize: var(--sp44x);

  }

  @media only screen and (max-width: ${props => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${props => props.theme.mediaQuery.DesktopSizeS}) {
    --headerLogoSize: var(--sp5-5x);
    --footerLogoSize: var(--sp31x);

  }

  @media only screen and (max-width: ${props => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${props => props.theme.mediaQuery.TabletSize}) {
    --headerLogoSize: var(--sp5-5x);
    --footerLogoSize: var(--sp22x);

  }

  @media only screen and (max-width: ${props => props.theme.mediaQuery.TabletSizeMin}) {
    --headerLogoSize: var(--sp5x);
    --footerLogoSize: var(--sp19x);
  }

`

export default LogoStyle