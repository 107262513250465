import styled from 'styled-components'

const CookiePopupStyle = styled.div`
	--messageWidth: 608px;
	--btnWrapHeight: 66px;
	--containerPad: var(--sp5x);
	--titleMB: var(--spSize);
	--bottomRightSize: var(--sp6x);
	--descriptionSize: var(--sp2x);
	--descriptionMB: var(--sp4x);

	position: fixed;
	bottom: var(--bottomRightSize);
	right: var(--bottomRightSize);
	width: var(--messageWidth);
	background: #efefef 0% 0% no-repeat padding-box;
	box-shadow: 0px 0px 20px #79797929;
	opacity: 1;
	z-index: 110;
	padding: var(--containerPad);

	.title {
		margin-bottom: var(--titleMB);
	}

	.cookieMessage,
	.description {
		margin-bottom: var(--descriptionMB);
		font-size: var(--descriptionSize);
	}

	.btnWrap {
		width: calc(var(--messageWidth) - 2 * var(--containerPad));
		background: #000000 0% 0% no-repeat padding-box;
		opacity: 1;
		height: var(--btnWrapHeight);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeM}) {
		--messageWidth: 456px;
		--btnWrapHeight: 52px;
		--containerPad: var(--sp4x);
		--titleMB: var(--spSize);
		--bottomRightSize: var(--sp4x);
		--descriptionSize: var(--sp1-5x);
		--descriptionMB: var(--sp3x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeS}) {
		--messageWidth: 454px;
		--btnWrapHeight: 47px;
		--containerPad: var(--sp4x);
		--titleMB: var(--spSize);
		--bottomRightSize: var(--sp4x);
		--descriptionSize: var(--sp1-5x);
		--descriptionMB: var(--sp3x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeXS}) {
		--messageWidth: 454px;
		--btnWrapHeight: 47px;
		--containerPad: var(--sp4x);
		--titleMB: var(--spSize);
		--bottomRightSize: var(--sp3x);
		--descriptionSize: var(--sp1-5x);
		--descriptionMB: var(--sp3x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.LaptopSize}) {
		--messageWidth: 454px;
		--btnWrapHeight: 47px;
		--containerPad: var(--sp4x);
		--titleMB: var(--spSize);
		--bottomRightSize: var(--sp3x);
		--descriptionSize: var(--sp1-5x);
		--descriptionMB: var(--sp3x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.TabletSize}) {
		--messageWidth: 454px;
		--btnWrapHeight: 47px;
		--containerPad: var(--sp4x);
		--titleMB: var(--spSize);
		--bottomRightSize: var(--sp3x);
		--descriptionSize: var(--sp1-5x);
		--descriptionMB: var(--sp3x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: 414px) {
		--messageWidth: 382px;
		--btnWrapHeight: 44px;
		--containerPad: var(--sp3x);
		--titleMB: var(--sp0-5x);
		--bottomRightSize: var(--sp2x);
		--descriptionSize: var(--sp1-5x);
		--descriptionMB: var(--sp3x);
	}

	@media only screen and (max-width: 413px) {
		--messageWidth: 345px;
		--btnWrapHeight: 42px;
		--containerPad: var(--sp2x);
		--titleMB: var(--sp0-5x);
		--bottomRightSize: var(--sp2x);
		--descriptionSize: var(--sp1-5x);
		--descriptionMB: var(--sp2x);
	}
`

export default CookiePopupStyle
