import React, { memo as Memo, useMemo } from 'react'
import Link from 'next/link'

import { withLanguageContext } from 'context'
import { withRouter } from 'next/router'

import LanguageSwitcherStyle from './style'

const LanguageSwitcher = Memo(({ selectLanguage, languages, ...props }) => {
    return (
        <LanguageSwitcherStyle className={`${props.className ? props.className : ''} FlexBox`}>
            {Object.keys(languages).map((lang, key) => {
                if (props.router.locale !== lang) {
                    return (
                        <Link key={key} href={props.router.asPath} locale={lang}>
                            <a className={`geoRegular p`}>{languages[lang].params.name}</a>
                        </Link>
                    )
                }
            })}
        </LanguageSwitcherStyle>
    )
})

export default withRouter(withLanguageContext(LanguageSwitcher, ['selectLanguage', 'languages']))
