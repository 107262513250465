import React, { forwardRef } from 'react'
import { UserConsumer } from 'context/providers/UserProvider'
import { getContext } from 'helpers'

export default function withUserContext(ConnectedComponent, properties = []) {
	return forwardRef((props, ref) => {
		return (
			<UserConsumer>
				{(context) => {
					let contextProps = properties.length ? getContext(context, properties) : {}
					return <ConnectedComponent {...contextProps} {...props} ref={ref} />
				}}
			</UserConsumer>
		)
	})
}
