import { memo as Memo, useRef, useCallback, useMemo, useEffect } from 'react'

//* Components
import { Input } from 'antd'

const FormInput = Memo(({ onClick, ...props }) => {
	//! Refs
	const ref = useRef()

	const Field = useMemo(() => {
		let field

		switch (props.type) {
			case 'textarea':
				field = <Input.TextArea rows={6} {...props} />
				break
			case 'password':
				field = <Input.Password ref={ref} {...props} />
				break
			default:
				field = <Input addonBefore={props.addonBefore} ref={ref} {...props} step={props.step && '0.1'} />
		}

		return field
	}, [props.type, props.value, ref])

	return Field
})

export default FormInput
