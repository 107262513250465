import React, { useRef, useImperativeHandle, forwardRef } from 'react'
import ContainerStyle from './style'

const Container = forwardRef((props, ref) => {

  const contRef = useRef()

  useImperativeHandle(ref, () => [contRef.current], [])

  return (
    <ContainerStyle
      ref={contRef}
      onWheel={props.onWheel}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      onMouseMove={props.onMouseMove}
      style={props.style}
      className={`${props.className || ''} ${props.large ? ' large' : ''}`}
    >
      {props.children}
    </ContainerStyle>
  )
})

export default Container
