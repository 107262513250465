import React, { memo as Memo, useMemo, useState } from 'react'

//* HOC's
import { withLanguageContext } from 'context'

//* Components
import { Form } from 'antd'
import Translate from 'components/common/Translate'

//* Helpers
import formValidation from '../../../helpers/_formValidation'

const FormItem = Memo(
    ({
        label,
        name,
        errorMessageName,
        type,
        validationType,
        className,
        required = true,
        min,
        max,
        message,
        autocomplete,
        extra,
        extraChild,
        rules = [],
        options = {},
        autoComplete = 'off',
        ...props
    }) => {
        const [active, setActive] = useState(false)

        const newRules = useMemo(() => {
            let r = [...rules]

            if (type == 'email') {
                r.push({ transform: (value) => value.trim() })
            }

            const typeObj = type ? { type } : ''
            r[0] = { ...r?.[0], message: '', required, ...typeObj }

            const params = r[0]

            if (required || validationType) {
                r[1] = ({ getFieldValue, setFields }) => ({
                    validator(_, value) {
                        const validType = validationType || params.type || 'string'
                        let validate

                        let settings
                        settings = min || typeof min === 'number' ? { ...settings, min: min } : settings
                        settings = max ? { ...settings, max: max } : settings

                        if (name === 'password_confirmation') {
                            validate = formValidation(Translate, { pass: getFieldValue('password'), c_pass: value }, 'c_password')

                            validate === true && setFields([{
                                name: 'password',
                                errors: [],
                            }])
                        } else {
                            validate = formValidation(Translate, value, validType, message, settings)
                        }

                        if (validate === true) {
                            return Promise.resolve()
                        }

                        return Promise.reject(validate)
                    },
                })
            }

            return r
        })

        return (
            <Form.Item
                onChange={props.onChange}
                label={label && props.translate(label)}
                hasFeedback
                extra={extra && (extraChild || props.translate(extra))}
                name={name}
                rules={newRules}
                required={required}
                onClick={setActive}
                className={`${className ? className : ''} ${active ? 'active' : ''}`}
                {...options}
            >
                {React.cloneElement(props.children, { autoComplete: 'off', onClick: setActive })}
            </Form.Item>
        )
    }
)

export default withLanguageContext(FormItem, ['translate', 'selectedLang'])
