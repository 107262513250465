import React from 'react'

//* Helpers
import config from 'helpers/_config'

//* Components
import { CustomLink, Text, Icon } from 'components/common'
import { useRouter } from 'next/router'
import { withUIContext, withUserContext, withCartContext } from 'context'

//* Styles
import HeaderCartStyle from './styles'

const HeaderCart = ({ winWidth, isLoggedIn, items_quantity }) => {
	const lowerThen1280 = winWidth < 1280
	const router = useRouter()
	const isHome = router.pathname === '/'
	const blogOrShop = router.pathname === '/blog/[slug]' || router.pathname === '/shop/[slug]'

	return (
		<HeaderCartStyle
			className={`FlexBox headerCart ${lowerThen1280 ? 'inHome' : ''} ${blogOrShop ? 'blogOrShop' : ''}`}
			isHome={!lowerThen1280 && isHome}
		>
			<CustomLink url={config.routes.account.path} className='cartLink'>
				<Text
					tag='p'
					className={`h8 geoRegular ${lowerThen1280 ? 'black' : isHome ? 'white' : 'black'}`}
					text={isLoggedIn ? 'account' : 'signin'}
				/>
			</CustomLink>
			<CustomLink url={config.routes.cart.path} ariaLabel='Cart icon' className='FlexBox alignMiddle basketLink'>
				<Icon className={`bw-Basket ${lowerThen1280 ? 'black' : isHome ? 'white' : 'black'}`} />
				<Text tag='p' className={`h8 geoRegular itemCount ${lowerThen1280 ? 'black' : isHome ? 'white' : 'black'}`} text={items_quantity} />
			</CustomLink>
		</HeaderCartStyle>
	)
}

export default withUserContext(withUIContext(withCartContext(HeaderCart, ['items_quantity']), ['winWidth']), ['isLoggedIn'])
