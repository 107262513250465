import React, { useMemo, forwardRef, useEffect, useRef, useState } from 'react'

//* HOCs
import { withLanguageContext } from 'context'
import withUIContext from 'context/consumerHOC/UIConsumer'

//* Components
import Text from 'components/common/Text'
import LottieAnimation from 'components/common/LottieAnimation'
import CustomLink from 'components/common/CustomLink'

//* Style
import ButtonStyle from './style'
import { wordCurvedAnim, storeText } from 'helpers'

//* Lottie
import arrow from 'lottieAnimation/arrow.json'
import arrowWithCircle from 'lottieAnimation/arrowWithCircle.json'

const Button = forwardRef((props, ref) => {
	const textRef = useRef()

	useEffect(() => {
		if (textRef?.current?.childNodes) {
			wordCurvedAnim(textRef.current.childNodes)
		}
	}, [props.preloader, textRef.current, props.text])

	const [arrowLottie, setArrowLottie] = useState(false)

	const btn = useMemo(() => {
		if (props.type === 'homeBtn') {
			return (
				<ButtonStyle
					onMouseEnter={() => setArrowLottie(true)}
					onMouseLeave={() => setArrowLottie(false)}
					ref={ref}
					className={`btn ${props.type} ${props.classNameParent ? props.classNameParent : ''} ${props.noTransition ? 'no-transition' : ''}`}
					onClick={props.onClick}
					iconColor={props.classNameParent}
				>
					<CustomLink url={props.link} className='FlexBox alignMiddle'>
						<Text className={`michroma h6 ${props.className ? props.className : ''}`} text={props.translate(props.text)} />
						{props.winWidth >= 1280 ? (
							<LottieAnimation
								animData={arrowWithCircle}
								autoplay={false}
								onCompletePause={!arrowLottie}
								isPlay={arrowLottie}
								loop={true}
							/>
						) : (
							<LottieAnimation
								animData={arrowWithCircle}
								autoplay={false}
								onCompletePause={false}
								isPlay={false}
								loop={false}
								isPaused={true}
							/>
						)}
					</CustomLink>
				</ButtonStyle>
			)
		} else if (props.type === 'bigBtn') {
			return (
				<ButtonStyle
					onMouseEnter={() => setArrowLottie(true)}
					onMouseLeave={() => setArrowLottie(false)}
					ref={ref}
					className={`btn FlexBox alignMiddle ${props.type} ${props.classNameParent ? props.classNameParent : ''} ${
						props.noTransition ? 'no-transition' : ''
					}`}
					onClick={props.onClick}
				>
					<a href={props.link} className='FlexBox alignMiddle'>
						<Text
							ref={textRef}
							className={`michroma h3 animLetterSpacing ${props.className ? props.className : ''}`}
							text={storeText(props.translate(props.text))}
						/>

						{props.winWidth >= 768 &&
							(props.winWidth >= 1280 ? (
								<LottieAnimation animData={arrow} autoplay={false} onCompletePause={!arrowLottie} isPlay={arrowLottie} loop={true} />
							) : (
								<LottieAnimation
									animData={arrow}
									autoplay={false}
									onCompletePause={false}
									isPlay={false}
									loop={false}
									isPaused={true}
								/>
							))}
					</a>
				</ButtonStyle>
			)
		}
	}, [props.className, arrowLottie, props.selectedLang])

	return btn
})
export default withUIContext(withLanguageContext(Button, ['translate', 'selectedLang']), ['winWidth'])
