import React, { memo as Memo, useEffect, useState } from 'react'

//* HOC's
import { withUIContext } from 'context'

//* Components
import LottieAnimation from 'components/common/LottieAnimation'

//* Style
import LoaderStyle from './style'

//* Lottie
import preloaderLottie from 'lottieAnimation/preloader.json'

const Loader = Memo(({ preloader, preloaderHide, ...props }) => {
    const [show, setShow] = useState(true)

    useEffect(() => {
        const timer = setTimeout(() => {
            setShow(false)
        }, 3500)
        return () => clearTimeout(timer)
    }, [])

    return show ? (
        <LoaderStyle className={`FlexBox ${show ? 'active' : ''}`} isShown={show}>
            <LottieAnimation animData={preloaderLottie} autoplay={true} loop={false} />
        </LoaderStyle>
    ) : null
})

export default withUIContext(Loader, ['preloader', 'preloaderHide'])
