import React, { useState, forwardRef } from 'react'
import gsap, { TweenLite } from 'gsap'

//* Components
import CustomLink from 'components/common/CustomLink'
import Text from 'components/common/Text'
import Icon from 'components/common/Icon'

//* Style
import AnimatedArrowButtonStyle from './style'

const AnimatedArrowButton = forwardRef((props, ref) => {
    const [startAnim, setStartAnim] = useState(false)

    if (startAnim) {
        gsap.to(ref?.current, { x: -5, yoyo: true, repeat: -1 })
    } else {
        TweenLite.killTweensOf(ref?.current)
        gsap.to(ref?.current, { x: 0 })
    }

    //! Checkers
    const checkOrder = props.order === 'iconFirst' ? 'rowReverse' : ''
    const isBlog = props.route === 'blog' ? 'blog' : ''
    const isCart = props.route === 'cart' ? '' : 'justifyEnd'

    return (
        <AnimatedArrowButtonStyle className={`FlexBox ${isCart} ${props.className}`} isBlog={isBlog}>
            <CustomLink
                url={props.path}
                className={`FlexBox alignMiddle buttonWrap uppercase ${checkOrder}`}
                onMouseLeave={() => {
                    setStartAnim(false)
                }}
                onMouseEnter={() => {
                    setStartAnim(true)
                }}
            >
                <Text tag={'p'} className={'h7 geoRegular'} text={props.text} />
                <div className={`FlexBox alignMiddle alignCenter circle ${props.isReverse ? 'reversed' : ''}`}>
                    <Icon className={`bw-arrow`} ref={ref} />
                </div>
            </CustomLink>
        </AnimatedArrowButtonStyle>
    )
})

export default AnimatedArrowButton
