import styled from 'styled-components'

const Header = styled.header.attrs((props) => ({}))`
	--paddingHeader: var(--sp8x) var(--sp6x);

	width: min-content;
	background-color: var(--lightWhite);
	padding: var(--paddingHeader);
	transition: background-color var(--trTime);
	position: fixed;
	top: 0 !important;
	left: 0;
	bottom: 0;
	z-index: 100;
	border-right: 1px solid var(--black);

	.headerLink {
		p {
			transition: var(--trTime2);
		}
		&:hover {
			color: var(--darkGrey);
			p {
				&.geoSemiBold {
					color: var(--black);
				}
				color: var(--darkGrey);
			}
		}
	}

	.inHome {
		align-self: center;
	}

	.lottieMainWrap {
		width: 6%;
		margin-left: 6%;
		cursor: pointer;
	}

	&.fixed {
		left: 0 !important;
		transform: translateX(0) !important;
	}

	&.black {
		background-color: var(--black);

		.bottom {
			.lang a {
				color: ${(props) => (props.langColor === 'black' ? 'var(--black)!important' : 'var(--lightWhite)!important')};
			}

			.routes {
				a {
					color: var(--white);
				}
			}
		}
	}

	.bottom {
		position: absolute;
		left: var(--sp8x);
		bottom: 0;
		transform: rotate(-90deg);
		transform-origin: left top;

		.lang {
			margin-left: var(--sp5x);
			a {
				transition: var(--trTime);
			}
		}

		.routes {
			margin-left: var(--spSize);
			a {
				display: inline-block;
				margin-right: var(--sp8x);
				transition: var(--trTime);
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.DesktopSizeM}) {
		--paddingHeader: var(--sp6x) var(--sp4x);

		.bottom {
			left: var(--sp5x);

			.lang {
				margin-left: var(--sp3x);
			}

			.routes {
				a {
					margin-right: var(--sp7x);
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.DesktopSizeXS}) {
		--paddingHeader: var(--sp5x) var(--sp3x);

		.bottom {
			left: var(--sp4x);

			.lang {
				margin-left: var(--sp3x);
			}

			.routes {
				a {
					margin-right: var(--sp6x);
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.TabletSize}) {
		--paddingHeader: var(--sp2x) var(--sp8x);

		.headerLink {
			&:hover {
				color: unset;
				p {
					&.geoSemiBold {
						color: var(--black);
					}
					color: unset;
				}
			}
		}

		border-right: none;
		width: 100%;
		height: auto;
		display: flex;
		position: relative;
		flex-direction: row;

		.bottom {
			position: relative;
			left: unset;
			bottom: unset;
			transform: rotate(0);
			margin-right: 35%;

			.routes {
				a {
					margin-right: var(--sp5x);
				}
			}

			.lang {
				margin-left: var(--sp3x);
			}
		}

		&.fixed {
			top: 0 !important;
			position: fixed !important;
		}

		&.inHome {
			flex-direction: row;
			align-items: center;
			bottom: unset;
			position: absolute;
			border-top: 1px solid #707070;
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) {
			--paddingHeader: var(--sp2x) var(--sp4x);

			.bottom {
				margin-right: 17%;
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		--paddingHeader: var(--sp2x);

		.headerLink {
			&:hover {
				color: unset;
				p {
					&.geoSemiBold {
						color: var(--black);
					}
					color: unset;
				}
			}
		}

		.blog {
			margin-left: var(--sp2x);
		}

		width: 100%;
		height: auto;
		display: flex;
		position: relative;
		flex-direction: row;
		border-right: none;

		.collapse {
			position: absolute;
			left: 0;
			top: 100%;
			transform: rotate(0);
			width: 100vw;
			background-color: var(--lightWhite);
			max-height: 0;
			overflow: hidden;
			transition: max-height var(--trTime2) ease-in;

			&.headerOpened {
				max-height: 100px;
				visibility: visible;
				transition: max-height var(--trTime2) ease-out;
			}

			& > div {
				padding: var(--paddingHeader);
				display: flex;
				align-items: center;
				justify-content: space-between;
				border-top: 1px solid var(--black);

				.routes {
					a {
						margin-right: var(--sp5x);
					}
				}

				.lang {
					margin-left: var(--sp3x);
				}
			}
		}

		&.fixed {
			top: 0 !important;
			position: fixed !important;
		}

		&.inHome {
			flex-direction: row;
			align-items: center;
			bottom: unset;
			position: absolute;
			border-top: 1px solid #707070;

			.inHome {
				align-self: center;
				margin-left: auto;
			}
		}
	}
`

export default Header
