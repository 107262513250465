import styled from 'styled-components'

const SingleBlogHomeStyle = styled.div`
	--bigPadding: var(--sp7x);
	min-width: var(--sp185x);
	width: var(--sp185x);
	height: 100vh;
	padding: var(--bigPadding);
	transition: 0.5s cubic-bezier(0.76, 0, 0.24, 1);
	word-break: break-word;

	.reading-time {
		i {
			margin-right: var(--sp2-5x);
			font-size: var(--sp4x);
		}
	}
	.date {
		margin-bottom: var(--sp5x);
	}
	.title {
		margin: var(--sp3x) 0 var(--sp5x);
	}

	.desc {
		margin-top: var(--sp11x);
	}

	.imageCont {
		width: 100%;
		padding-top: 52.5%;
	}

	&:nth-child(1) {
		background-color: var(--grey);
		padding-top: var(--sp19x);
		transform: translateX(30%);

		&.active {
			transform: translateX(0);
		}
	}

	&:nth-child(2) {
		background-color: var(--darkGrey);

		&.active {
			transform: translateX(-50%);

			& ~ a {
				transform: translateX(-50%);
			}
		}

		.reading-time {
			margin-top: var(--sp7x);
		}

		.imageCont {
			order: -1;
		}
	}

	&:nth-child(3) {
		background-color: var(--ultraDarkGrey);
		justify-content: center;

		&.active {
			transform: translateX(-100%) !important;

			& ~ a {
				transform: translateX(-100%);
			}
		}

		.imageCont {
			margin-top: var(--sp7x);
		}
	}

	&:nth-child(4) {
		background-color: var(--grey);
		justify-content: center;

		&.active {
			transform: translateX(-150%) !important;
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeM}) {
		--bigPadding: var(--sp6x);
		min-width: var(--sp138x);
		width: var(--sp138x);

		.reading-time {
			i {
				margin-right: var(--spSize);
				font-size: var(--sp2-5x);
			}
		}

		.date {
			margin-bottom: var(--sp4x);
		}

		.title {
			margin: var(--sp2-5x) 0 var(--sp4x);
		}

		.desc {
			margin-top: var(--sp9x);
		}

		&:nth-child(1) {
			padding-top: var(--sp14x);
		}

		&:nth-child(2) {
			.reading-time {
				margin-top: var(--sp5x);
			}
		}

		&:nth-child(3) {
			.imageCont {
				margin-top: var(--sp5x);
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeXS}) {
		--bigPadding: var(--sp5x);
		min-width: var(--sp108x);
		width: var(--sp108x);

		.date {
			margin-bottom: var(--sp3x);
		}

		.reading-time {
			i {
				margin-right: var(--spSize);
				font-size: var(--sp2x);
			}
		}

		.title {
			margin: var(--sp2x) 0 var(--sp3x);
		}

		.desc {
			margin-top: var(--sp7x);
		}

		&:nth-child(1) {
			padding-top: var(--sp12x);
		}

		&:nth-child(2) {
			.reading-time {
				margin-top: var(--sp2-5x);
			}
		}

		&:nth-child(3) {
			.imageCont {
				margin-top: var(--sp2-5x);
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.TabletSize}) {
		--bigPadding: var(--sp4x);
		min-width: unset;
		width: 100%;

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) {
			height: fit-content;
		}

		.reading-time {
			i {
				margin-right: var(--spSize);
				font-size: var(--sp2x);
			}
		}

		.date {
			margin-bottom: var(--sp3x);
		}

		.title {
			margin: var(--sp2x) 0 var(--sp3x);
		}

		.desc {
			margin-top: var(--sp6x);
		}

		&:nth-child(1) {
			padding-top: var(--sp12x);
		}

		&:nth-child(2) {
			&.active {
				transform: translate(0, 0);
			}

			.reading-time {
				margin-top: var(--sp2-5x);
			}
		}

		&:nth-child(3) {
			&.active {
				transform: translate(0, 0) !important;
			}
			.imageCont {
				margin-top: var(--sp2-5x);
			}
		}

		&:nth-child(4) {
			&.active {
				transform: translate(0, -15%) !important;
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		--bigPadding: var(--sp2-5x);
		min-width: unset;
		width: 100%;
		height: fit-content;

		.reading-time {
			i {
				margin-right: var(--spSize);
				font-size: var(--sp2x);
			}
		}

		.date {
			margin-bottom: var(--sp3x);
		}

		.title {
			margin: var(--sp2x) 0 var(--sp3x);
		}

		.desc {
			margin-top: var(--sp6x);
		}

		&:nth-child(1) {
			padding-top: var(--sp2-5x);
		}

		&:nth-child(2) {
			height: fit-content;
			&.active {
				transform: translate(0, -55%);
			}

			.reading-time {
				margin-top: var(--sp2-5x);
			}
		}

		&:nth-child(3) {
			height: fit-content;
			&.active {
				transform: translate(0, -65%) !important;
			}
			.imageCont {
				margin-top: var(--sp2-5x);
			}
		}

		&:nth-child(4) {
			&.active {
				transform: translate(0, -100%) !important;
			}
		}
	}
`

export default SingleBlogHomeStyle
