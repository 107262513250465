import React, { useMemo } from 'react'

//* Components
import Text from 'components/common/Text'
import Icon from 'components/common/Icon'
import CustomLink from 'components/common/CustomLink'

//* Style
import BackButtonStyle from './style'
import { withDataContext } from 'context'

const BackButton = ({ backRoute, prevRoute, ...props }) => {
    //! Checkers
    const checkRoute = useMemo(() => (prevRoute?.asPath === '/' ? '/' : backRoute), [prevRoute?.asPath, backRoute])

    return (
        <BackButtonStyle>
            <CustomLink className='alignMiddle backButton' role='button' url={checkRoute}>
                <Icon className='bw-Arrow_back' />
                <Text tag={'h7'} className={'h7 geoRegular uppercase'} text={'back'} />
            </CustomLink>
        </BackButtonStyle>
    )
}

export default withDataContext(BackButton, ['prevRoute'])
