//* Main
import React, { createContext, Component } from 'react'
import { withRouter } from 'next/router'

//* Helpers
import { api, asyncFunctionDecorator, removeCookie, setCookie, checkUserRedirections } from 'helpers'

const UserContext = createContext(null)

class UserProvider extends Component {
    state = {
        ...this.props.userProps,
    }

    methods = {
        register: this.register.bind(this),
        login: this.login.bind(this),
        forgotPassword: this.forgotPassword.bind(this),
        resetPassword: this.resetPassword.bind(this),
        updateAccount: this.updateAccount.bind(this),
        changePassword: this.changePassword.bind(this),
        logout: this.logout.bind(this),
    }

    componentDidUpdate(prevProps) {
        if (prevProps.router.asPath !== this.props.router.asPath) {
            checkUserRedirections({
                lang: this.props.router.locale,
                user: this.state.user,
                ctx: this.props.router,
                redirectAutomatically: true,
            })
        }
    }

    register(data) {
        return asyncFunctionDecorator(() => api.post('register', data))
    }

    login(data) {
        return asyncFunctionDecorator(() =>
            api.post('login', data).then(({ accessToken, user }) => {
                setCookie('accessToken', accessToken)

                this.setState(
                    {
                        isLoggedIn: true,
                        user,
                    },
                    () => {
                        this.props.router.push('/')
                    }
                )
            })
        )
    }

    forgotPassword(data) {
        return asyncFunctionDecorator(() => api.post('forgot-password', data))
    }

    resetPassword(data) {
        return asyncFunctionDecorator(() => api.post('reset-password', data))
    }

    updateAccount(data) {
        return asyncFunctionDecorator(() =>
            api.post('update-account', data).then(() => {
                this.setState({
                    ...this.state,
                    user: {
                        ...this.state.user,
                        ...data,
                    },
                })
            })
        )
    }

    changePassword(data) {
        return asyncFunctionDecorator(() => api.post('update-password', data))
    }

    logout() {
        return asyncFunctionDecorator(() =>
            api.post('logout').then(() => {
                removeCookie('accessToken')

                this.setState(
                    {
                        isLoggedIn: false,
                        user: null,
                    },
                    () => {
                        this.props.router.push('/')
                    }
                )
            })
        )
    }

    render() {
        return (
            <UserContext.Provider value={{ ...this.state, ...this.methods }}>
                {this.props.children}
            </UserContext.Provider>
        )
    }
}

export default withRouter(UserProvider)
export const UserConsumer = UserContext.Consumer
