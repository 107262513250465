import React, { memo as Memo, useMemo } from 'react'

//* HOCs
import { withLanguageContext, withUIContext, withDataContext } from 'context'

//* Styles
import Footer from './style'

const AppFooter = Memo(({ global, selectedLang, winWidth, ...props }) => {

    return (
        <Footer className="footer">
            footer
        </Footer>
    )
})

export default withLanguageContext(withUIContext(withDataContext(AppFooter, ['global']), ['screenSizes', 'winWidth']), ['translate', 'selectedLang'])