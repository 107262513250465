import { memo as Memo, useMemo, useEffect } from 'react'
import { useRouter } from 'next/router'


//* HOCs
import { withUIContext } from 'context'

//* Components
import { NextSeo } from 'next-seo'
import HeaderCart from 'components/common/HeaderCart'

//* Styles
import PageStyle from './style'

const Page = Memo(({ winWidth, meta, ...props }) => {
    //! Router
    const router = useRouter()

    //! URL
    const url = useMemo(() => process.env.host + (router.locale !== 'fr' ? '/' + router.locale : '') + router.asPath, [router])

    //! Description
    const description = useMemo(() => meta?.description || 'Bauwssen meta description', [meta?.description])

    //! Title
    const title = useMemo(() => meta?.title || 'Bauwssen', [meta?.title])

    useEffect(() => {
        window.scrollTo({ top: 0 })
    }, [])

    return (
        <>
            <NextSeo
                title={title}
                description={description}
                canonical={url}
                type='website'
                additionalMetaTags={[
                    {
                        name: 'keywords',
                        content: meta?.keywords,
                    },
                ]}
                openGraph={{
                    url: url,
                    title: title,
                    description: description,
                    images: [{ url: meta?.image || process.env.host + '/images/logo.svg', alt: title }],
                }}
                twitter={{
                    handle: '@handle',
                    site: '@site',
                    cardType: 'summary_large_image',
                }}
            />

            <PageStyle style={props.style} className={props.className}>
                {props.children}
                {winWidth < 1280 ? null : <HeaderCart />}
            </PageStyle>
        </>
    )
})

export default withUIContext(Page, ['winWidth'])
