import App from 'next/app'
import React from 'react'

//* HOC's
import { UIProvider, DataProvider, LanguageProvider, UserProvider, CartProvider } from 'context'

//* Components
import AppHeader from 'components/common/AppHeader'
import Preloader from 'components/common/Preloader'
import CookiePopup from 'components/common/CookiePopup'

//* Helpers
import { getData, getPageParams, config, getUser } from 'helpers'

//* Style
import 'styles/icons/style.css'
import 'styles/select/select.css'
import 'styles/font.css'

class MyApp extends App {
    static async getInitialProps({ Component, ctx, router, ...props }) {
        //! Checking If First Request or Not
        if (!process.browser) {

            //! Default States
            const newState = {
                pages: {},
                global: {}
            }

            let userData = {
                isLoggedIn: false
            }

            //! Getting User Data
            userData = await getUser(ctx.locale, userData, ctx)

            //! Checking is private page and not logged in
            userData.redirect && ctx.res.redirect(userData.redirectUrl || '/sign-in')

            //! Getting Page Data
            const data = await getData({ lang: ctx.locale, state: newState, router: ctx, getPageParams: true })

            return {
                pageProps: {
                    ...data,
                    browser: false,
                },
                userProps: userData.newState,
            }
        } else {
            return {
                pageProps: {
                    pageParams: getPageParams({ lang: ctx.locale, router: ctx }),
                    browser: true,
                },
            }
        }
    }

    render() {
        const { Component, pageProps, router, userProps } = this.props

        return (
            <LanguageProvider>
                <UIProvider>
                    <DataProvider newData={pageProps}>
                        <UserProvider userProps={userProps}>
                            <CartProvider>
                                <Preloader />
                                {router.pathname !== '/404' && <AppHeader />}
                                <section className='min-height'>
                                    <Component {...pageProps} config={config.routes} selectedLang={router.locale} />
                                </section>
                                <CookiePopup />
                            </CartProvider>
                        </UserProvider>
                    </DataProvider>
                </UIProvider>
            </LanguageProvider>
        )
    }
}

export default MyApp
