import { useImperativeHandle, forwardRef, useRef } from 'react'
import { withUIContext } from 'context'
import Text from 'components/common/Text'
import Image from 'components/common/Image'
import Icon from 'components/common/Icon'
import CustomLink from 'components/common/CustomLink'

import SingleBlogHomeStyle from './style'

const SingleBlogHome = forwardRef((props, ref) => {
    const refs = useRef()

    useImperativeHandle(ref, () => {
        return refs.current
    })

    return (
        <SingleBlogHomeStyle ref={refs} className={`FlexBoxColumn ${props.winWidth < 1280 && 'active'}`}>
            <CustomLink url={props.slug}>
                <div className='reading-time FlexBox alignMiddle'>
                    <Icon className='bw-clock_in_blog lightWhite' />
                    <Text tag='p' className='h7 geoRegular lightWhite' text={props.readingTime} />
                    &nbsp;
                    <Text tag='p' className='h7 geoRegular lightWhite' text={'minRead'} />
                </div>
                <Text tag='h5' className='title h5 michroma lightWhite' text={props.title} />
                <Text tag='p' className='date h7 geoRegular lightWhite' text={props.date} />
                {props.photo === '' ? (
                    <Text tag='p' className='desc h7 geoRegular lightWhite' text={props.description} />
                ) : (
                    <Image src={props.photo} />
                )}
            </CustomLink>
        </SingleBlogHomeStyle>
    )
})

export default withUIContext(SingleBlogHome, ['winWidth'])
