import { createGlobalStyle } from 'styled-components'

const Typography = createGlobalStyle`
  /* Typography */

  * {
    box-sizing: border-box;
    -webkit-appearance: none;
    outline: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0)!important;
    -webkit-font-smoothing: antialiased;
  }

  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  input:-internal-autofill-selected {
    background-color: transparent !important;
    box-shadow: inset 1000px 1000px #fff;
  }

  input:focus::placeholder {
    color: transparent;
  }

  input {
    outline: none;
  }

  input:-webkit-autofill {
    -webkit-text-fill-color: var(--textColor) !important;
    -webkit-box-shadow: inset 0 0 0 50px var(--textColor) !important;
  }

//ant select box drop down style
.ant-select-dropdown{
    box-shadow: 0px 0px 8px #AAAAAA;
    border-radius: 0px;
    padding:var(--sp5x) var(--sp2x) var(--sp5x) var(--sp5x);
    
    .rc-virtual-list-holder{
      .rc-virtual-list-holder-inner{
        .ant-select-item-option{
          cursor: pointer;
          margin-bottom: var(--sp3x);
            &:nth-last-child(1) {
              margin-bottom: 0;
          }
          .ant-select-item-option-state{
            display: none;
          }
          &.ant-select-item-option-active{
            .ant-select-item-option-content{
              color: var(--black);
              font-size: var(--h7);
              font-family: geoRegular;
            }
            .ant-select-item-option-state{
              display: none;
            }
          }
          .ant-select-item-option-content{
            color: var(--lightGrey);
            font-size: var(--h7);
            font-family: geoRegular;
          }
        }
      }
    }
  }

@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
	props.theme.mediaQuery.DesktopSizeM}) {
    .ant-select-dropdown{
      padding:var(--sp4x) var(--sp2x) var(--sp4x) var(--sp4x);

      .rc-virtual-list-holder{
        .rc-virtual-list-holder-inner{
          .ant-select-item-option{
            margin-bottom: var(--sp2-5x);
          }
        }
      }
    }
}

@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
	props.theme.mediaQuery.DesktopSizeS}) {
    .ant-select-dropdown{
      padding:var(--sp5x) var(--sp2x) var(--sp5x) var(--sp5x);

      .rc-virtual-list-holder{
        .rc-virtual-list-holder-inner{
          .ant-select-item-option{
            margin-bottom: var(--sp3x);
          }
        }
      }
    }
}

@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
	props.theme.mediaQuery.DesktopSizeXS}) {
    .ant-select-dropdown{
      padding:var(--sp3x) var(--sp2x) var(--sp3x) var(--sp3x);

      .rc-virtual-list-holder{
        .rc-virtual-list-holder-inner{
          .ant-select-item-option{
            margin-bottom: var(--sp2x);
          }
        }
      }
    }
}

@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) =>
	props.theme.mediaQuery.TabletSize}) {
    .ant-select-dropdown{
      padding:var(--sp3x) var(--sp1-25x) var(--sp3x) var(--sp3x);

      .rc-virtual-list-holder{
        .rc-virtual-list-holder-inner{
          .ant-select-item-option{
            margin-bottom: var(--sp2x);
          }
        }
      }
    }
}

@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
  .ant-select-dropdown{
      padding:var(--sp3x) var(--sp2x) var(--sp3x) var(--sp3x);

      .rc-virtual-list-holder{
        .rc-virtual-list-holder-inner{
          .ant-select-item-option{
            margin-bottom: var(--sp2x);
          }
        }
      }
    }
}
//end  

  /* Change autocomplete styles in WebKit */
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: 2px solid black;
    -webkit-text-fill-color: black;
    color: white;
    background-color: transparent;
    -webkit-box-shadow: 0 0 0 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
  }

  /* Change the white to any color ;) */
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px transparent inset !important;
  }

  @-webkit-keyframes autofill {
    to {
      background: transparent;
    }
  }

  a {
    text-decoration: none;
    color: var(--black);
  }


  //! Text capitalize

  .uppercase {
    text-transform: uppercase;
  }

  .lowercase {
    text-transform: lowercase;
  }

  .capitalize {
    text-transform: capitalize;
  }

  .underline {
    text-decoration: underline;
  }

  .lineThrough {
    text-decoration: line-through;
  }

  //!Fonts

  .geoRegular {
    font-family: geoRegular;
  }
  .geoSemiBold {
    font-family: geoSemiBold;
  }

  .geoLight {
    font-family: geoLight;
  }

  .grandItalic {
    font-family: grandItalic;
  }

  .grandRoman {
    font-family: grandRoman;
  }

  .michroma {
    font-family: michroma;
  }



  //!Colors
  .black {
    color: var(--black);
  }

  .white {
    color: var(--white);
  }

  .lightWhite {
    color: var(--lightWhite);
  }
  
  .grey {
    color: var(--grey);
  }

  .lightGrey {
    color: var(--lightGrey);
  }

  .darkGrey {
    color: var(--darkGrey);
  }

  .ultraDarkGrey {
    color: var(--ultraDarkGrey);
  }

  .errorRed {
    color: var(--errColor);
  }

  //!Font Sizes

  .h1,
  h1 {
    font-size: var(--h1);
    margin: 0;
    line-height: var(--lineHeightXS);
    font-weight: unset;

  }

  .h2,
  h2 {
    font-size: var(--h2);
    margin: 0;
    line-height: var(--lineHeightXS);
    font-weight: unset;
  }

  .h3,
  h3 {
    font-size: var(--h3);
    margin: 0;
    line-height: var(--lineHeightXS);
    font-weight: unset;
  }

  .h4,
  h4 {
    font-size: var(--h4);
    margin: 0;
    line-height: var(--lineHeightXS);
    font-weight: unset;
  }

  .h5,
  h5 {
    font-size: var(--h5);
    margin: 0;
    line-height: var(--lineHeightS);
    font-weight: unset;
  }

  .h6,
  h6 {
    font-size: var(--h6);
    margin: 0;
    line-height: var(--lineHeight);
    font-weight: unset;
  }

  .h7 {
    font-size: var(--h7);
    margin: 0;
    line-height: var(--lineHeight);
    font-weight: unset;
  }

  .h8 {
    font-size: var(--h8);
    margin: 0;
    line-height: var(--lineHeight);
    font-weight: unset;
  }

  .h9 {
    font-size: var(--h9);
    margin: 0;
    line-height: var(--lineHeight);
    font-weight: unset;
  }

  .p, p {
    font-size: var(--p);
    margin: 0;
    line-height: var(--lineHeight);
    font-weight: unset;
  }

`

export default Typography
