import { createGlobalStyle } from 'styled-components'

const Variables = createGlobalStyle`

:root {
    //!Colors
    --black:${(props) => props.theme.colors.black};
    --white: ${(props) => props.theme.colors.white};
    --lightWhite:${(props) => props.theme.colors.lightWhite};
    --grey:${(props) => props.theme.colors.grey};
    --lightGrey:${(props) => props.theme.colors.lightGrey};
    --darkGrey:${(props) => props.theme.colors.darkGrey};
    --ultraDarkGrey:${(props) => props.theme.colors.ultraDarkGrey};
    --errColor:${(props) => props.theme.colors.errColor};

    //!Screen Sizes
    --DesktopSizeL:${(props) => props.theme.mediaQuery.DesktopSizeL};
    --DesktopSizeS: ${(props) => props.theme.mediaQuery.DesktopSizeS};
    --DesktopSizeXS: ${(props) => props.theme.mediaQuery.DesktopSizeXS};
    --LaptopSize: ${(props) => props.theme.mediaQuery.LaptopSize};
    --TabletSize: ${(props) => props.theme.mediaQuery.TabletSize};
    --MobileSize: ${(props) => props.theme.mediaQuery.MobileSize};

    //!Colors

    --textColor:${(props) => props.theme.colors.textColor};
    --backColor: ${(props) => props.theme.colors.backColor};
    --errColor: ${(props) => props.theme.mediaQuery.errColor};

    //*Spacing
    --spSize: 8px;
    
    --sp0-5x: calc(var(--spSize) * 0.5);
    --sp0-75x: calc(var(--spSize) * 0.75);
    --sp1x: calc(var(--spSize));
    --sp1-1x: calc(var(--spSize) * 1.1);
    --sp1-25x: calc(var(--spSize) * 1.25);
    --sp1-3x: calc(var(--spSize) * 1.3);
    --sp1-4x: calc(var(--spSize) * 1.4);
    --sp1-5x: calc(var(--spSize) * 1.5);
    --sp1-6x: calc(var(--spSize) * 1.7);
    --sp1-75x: calc(var(--spSize) * 1.75);
    --sp2x: calc(var(--spSize) * 2);
    --sp2-25x: calc(var(--spSize) * 2.25);
    --sp2-5x: calc(var(--spSize) * 2.5);
    --sp2-8x: calc(var(--spSize) * 2.8);
    --sp3x: calc(var(--spSize) * 3);
    --sp3-25x: calc(var(--spSize) * 3.25);
    --sp3-5x: calc(var(--spSize) * 3.5);
    --sp3-75x: calc(var(--spSize) * 3.75);
    --sp4x: calc(var(--spSize) * 4);
    --sp4-5x: calc(var(--spSize) * 4.5);
    --sp4-75x: calc(var(--spSize) * 4.75);
    --sp5x: calc(var(--spSize) * 5);
    --sp5-5x: calc(var(--spSize) * 5.5);
    --sp6x: calc(var(--spSize) * 6);
    --sp6-25x: calc(var(--spSize) * 6.25);
    --sp6-5x: calc(var(--spSize) * 6.5);
    --sp7x: calc(var(--spSize) * 7);
    --sp7-5x: calc(var(--spSize) * 7.5);
    --sp8x: calc(var(--spSize) * 8);
    --sp8-25x: calc(var(--spSize) * 8.25);
    --sp8-5x: calc(var(--spSize) * 8.5);
    --sp9x: calc(var(--spSize) * 9);
    --sp9-5x: calc(var(--spSize) * 9.5);
    --sp10x: calc(var(--spSize) * 10);
    --sp10-75x: calc(var(--spSize) * 10.75);
    --sp11x: calc(var(--spSize) * 11);
    --sp11-5x: calc(var(--spSize) * 11.5);
    --sp12x: calc(var(--spSize) * 12);
    --sp12-5x: calc(var(--spSize) * 12.5);
    --sp13x: calc(var(--spSize) * 13);
    --sp13-5x: calc(var(--spSize) * 13.5);
    --sp14x: calc(var(--spSize) * 14);
    --sp14-5x: calc(var(--spSize) * 14.5);
    --sp15x: calc(var(--spSize) * 15);
    --sp15-5x: calc(var(--spSize) * 15.5);
    --sp16x: calc(var(--spSize) * 16);
    --sp16-5x: calc(var(--spSize) * 16.5);
    --sp17x: calc(var(--spSize) * 17);
    --sp17-5x: calc(var(--spSize) * 17.5);
    --sp18x: calc(var(--spSize) * 18);
    --sp18-5x: calc(var(--spSize) * 18.5);
    --sp19x: calc(var(--spSize) * 19);
    --sp19-5x: calc(var(--spSize) * 19.5);
    --sp19-75x: calc(var(--spSize) * 19.75);
    --sp20x: calc(var(--spSize) * 20);
    --sp20-5x: calc(var(--spSize) * 20.5);
    --sp20-75x: calc(var(--spSize) * 20.75);
    --sp21x: calc(var(--spSize) * 21);
    --sp21-5x: calc(var(--spSize) * 21.5);
    --sp22x: calc(var(--spSize) * 22);
    --sp22-5x: calc(var(--spSize) * 22.5);
    --sp23x: calc(var(--spSize) * 23);
    --sp24x: calc(var(--spSize) * 24);
    --sp24-5x: calc(var(--spSize) * 24.5);
    --sp25x: calc(var(--spSize) * 25);
    --sp25-5x: calc(var(--spSize) * 25.5);
    --sp26x: calc(var(--spSize) * 26);
    --sp27x: calc(var(--spSize) * 27);
    --sp27-5x: calc(var(--spSize) * 27.5);
    --sp28x: calc(var(--spSize) * 28);
    --sp28-25x: calc(var(--spSize) * 28.25);
    --sp28-5x: calc(var(--spSize) * 28.5);
    --sp29x: calc(var(--spSize) * 29);
    --sp30x: calc(var(--spSize) * 30);
    --sp30-75x: calc(var(--spSize) * 30.75);
    --sp31x: calc(var(--spSize) * 31);
    --sp31-5x: calc(var(--spSize) * 31.5);
    --sp32x: calc(var(--spSize) * 32);
    --sp33x: calc(var(--spSize) * 33);
    --sp34x: calc(var(--spSize) * 34);
    --sp35x: calc(var(--spSize) * 35);
    --sp36x: calc(var(--spSize) * 36);
    --sp37x: calc(var(--spSize) * 37);
    --sp38x: calc(var(--spSize) * 38);
    --sp38-5x: calc(var(--spSize) * 38.5);
    --sp39x: calc(var(--spSize) * 39);
    --sp40x: calc(var(--spSize) * 40);
    --sp40-5x: calc(var(--spSize) * 40.5);
    --sp41x: calc(var(--spSize) * 41);
    --sp41-5x: calc(var(--spSize) * 41.5);
    --sp42x: calc(var(--spSize) * 42);
    --sp42-5x: calc(var(--spSize) * 42.5);
    --sp43x: calc(var(--spSize) * 43);
    --sp44x: calc(var(--spSize) * 44);
    --sp45x: calc(var(--spSize) * 45);
    --sp46x: calc(var(--spSize) * 46);
    --sp46-5x: calc(var(--spSize) * 46.5);
    --sp47x: calc(var(--spSize) * 47);
    --sp47-5x: calc(var(--spSize) * 47.5);
    --sp48x: calc(var(--spSize) * 48);
    --sp49x: calc(var(--spSize) * 49);
    --sp50x: calc(var(--spSize) * 50);
    --sp50-5x: calc(var(--spSize) * 50.5);
    --sp51x: calc(var(--spSize) * 51);
    --sp52x: calc(var(--spSize) * 52);
    --sp52-25x: calc(var(--spSize) * 52.25);
    --sp53x: calc(var(--spSize) * 53);
    --sp53-5x: calc(var(--spSize) * 53.5);
    --sp53-75x: calc(var(--spSize) * 53.75);
    --sp54x: calc(var(--spSize) * 54);
    --sp55x: calc(var(--spSize) * 55);
    --sp56x: calc(var(--spSize) * 56);
    --sp57x: calc(var(--spSize) * 57);
    --sp57-5x: calc(var(--spSize) * 57.5);
    --sp58x: calc(var(--spSize) * 58);
    --sp59x: calc(var(--spSize) * 59);
    --sp60x: calc(var(--spSize) * 60);
    --sp60-5x: calc(var(--spSize) * 60.5);
    --sp61x: calc(var(--spSize) * 61);
    --sp62x: calc(var(--spSize) * 62);
    --sp63x: calc(var(--spSize) * 63);
    --sp63-5x: calc(var(--spSize) * 63.5);
    --sp64x: calc(var(--spSize) * 64);
    --sp65x: calc(var(--spSize) * 65);
    --sp66x: calc(var(--spSize) * 66);
    --sp67x: calc(var(--spSize) * 67);
    --sp67-5x: calc(var(--spSize) * 67.5);
    --sp68x: calc(var(--spSize) * 68);
    --sp68-5x: calc(var(--spSize) * 68.5);
    --sp69x: calc(var(--spSize) * 69);
    --sp70x: calc(var(--spSize) * 70);
    --sp72x: calc(var(--spSize) * 72);
    --sp72-5x: calc(var(--spSize) * 72.5);
    --sp73x: calc(var(--spSize) * 73);
    --sp75x: calc(var(--spSize) * 75);
    --sp76x: calc(var(--spSize) * 76);
    --sp77x: calc(var(--spSize) * 77);
    --sp78x: calc(var(--spSize) * 78);
    --sp79x: calc(var(--spSize) * 79);
    --sp80x: calc(var(--spSize) * 80);
    --sp81x: calc(var(--spSize) * 81);
    --sp81-5x: calc(var(--spSize) * 81.5);
    --sp82x: calc(var(--spSize) * 82);
    --sp83x: calc(var(--spSize) * 83);
    --sp84x: calc(var(--spSize) * 84);
    --sp85x: calc(var(--spSize) * 85);
    --sp85-5x: calc(var(--spSize) * 85.5);
    --sp86x: calc(var(--spSize) * 86);
    --sp87x: calc(var(--spSize) * 87);
    --sp88x: calc(var(--spSize) * 88);
    --sp88-5x: calc(var(--spSize) * 88.5);
    --sp89x: calc(var(--spSize) * 89);
    --sp90x: calc(var(--spSize) * 90);
    --sp91x: calc(var(--spSize) * 91);
    --sp92x: calc(var(--spSize) * 92);
    --sp93x: calc(var(--spSize) * 93);
    --sp94x: calc(var(--spSize) * 94);
    --sp96x: calc(var(--spSize) * 96);
    --sp97x: calc(var(--spSize) * 97);
    --sp98x: calc(var(--spSize) * 98);
    --sp99x: calc(var(--spSize) * 99);
    --sp100x: calc(var(--spSize) * 100);
    --sp101x: calc(var(--spSize) * 101);
    --sp102x: calc(var(--spSize) * 102);
    --sp103x: calc(var(--spSize) * 103);
    --sp104x: calc(var(--spSize) * 104);
    --sp106x: calc(var(--spSize) * 106);
    --sp106-5x: calc(var(--spSize) * 106.5);
    --sp108x: calc(var(--spSize) * 108);
    --sp110x: calc(var(--spSize) * 110);
    --sp110-5x: calc(var(--spSize) * 110.5);
    --sp112x: calc(var(--spSize) * 112);
    --sp115x: calc(var(--spSize) * 115);
    --sp117x: calc(var(--spSize) * 117);
    --sp118x: calc(var(--spSize) * 118);
    --sp120x: calc(var(--spSize) * 120);
    --sp125x: calc(var(--spSize) * 125);
    --sp128x: calc(var(--spSize) * 128);
    --sp129x: calc(var(--spSize) * 129);
    --sp123x: calc(var(--spSize) * 123);
    --sp132x: calc(var(--spSize) * 132);
    --sp135x: calc(var(--spSize) * 135);
    --sp135-25x: calc(var(--spSize) * 135.25);
    --sp136x: calc(var(--spSize) * 136);
    --sp138x: calc(var(--spSize) * 138);
    --sp140x: calc(var(--spSize) * 140);
    --sp141x: calc(var(--spSize) * 141);
    --sp142x: calc(var(--spSize) * 142);
    --sp148x: calc(var(--spSize) * 148);
    --sp149x: calc(var(--spSize) * 149);
    --sp150x: calc(var(--spSize) * 150);
    --sp152x: calc(var(--spSize) * 152);
    --sp153x: calc(var(--spSize) * 153);
    --sp159x: calc(var(--spSize) * 159);
    --sp160x: calc(var(--spSize) * 160);
    --sp166x: calc(var(--spSize) * 166);
    --sp175x: calc(var(--spSize) * 175);
    --sp180x: calc(var(--spSize) * 180);
    --sp182x: calc(var(--spSize) * 182);
    --sp185x: calc(var(--spSize) * 185);
    --sp200x: calc(var(--spSize) * 200);
    --sp205x: calc(var(--spSize) * 205);
    --sp210x: calc(var(--spSize) * 210);
    --sp221x: calc(var(--spSize) * 221);

    //transition
    --trTime: 0.3s;
    --trTime2: 0.5s;
    --trTime3: 0.7s;
    --trTime4: 1s;

    --transitionTime: var(--trTime);
    --transitionTime2: var(--trTime2);
    --transitionTime3: var(--trTime3);

    //line heights
    --lineHeight: 1.5;
    --lineHeightS: 1.4;
    --lineHeightXS: 1.2;

    //cont
    --maxWidth:100%;
}

@media only screen and  (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
    :root {
        --h1: 288px;
        --h2: 192px;
        --h3: 128px;
        --h4: 72px;
        --h5: 56px;
        --h6: 40px;
        --h7: 32px;
        --h8: 24px;
        --p:20px;

        //grid 
        --containerPadding: var(--sp25x);
        --containerPaddingLarge: var(--sp8x);
        --containerPaddingHome:var(--sp17x);

        //section margin bottom
        --sectionMarginBottom:var(--sp25x);
        --sectionMarginBottomHome:var(--sp29x);

        --secToSecMarginHorizontal:var(--sp29x);

    }
}


@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
	props.theme.mediaQuery.DesktopSizeM}) {

    :root {
        --h1: 216px;
        --h2: 144px;
        --h3: 96px;
        --h4: 56px;
        --h5: 40px;
        --h6: 32px;
        --h7: 24px;
        --h8: 20px;
        --p:18px;

        //grid 
        --containerPadding: var(--sp22x);
        --containerPaddingLarge: var(--sp7x);
        --containerPaddingHome:var(--sp10x);

            //section margin bottom
        --sectionMarginBottom:var(--sp22x);
        --sectionMarginBottomHome:var(--sp19x);

        --secToSecMarginHorizontal:var(--sp19x);
    }
}

@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
	props.theme.mediaQuery.DesktopSizeS}) {
    :root {
        --h1: 168px;
        --h2: 112px;
        --h3: 72px;
        --h4: 48px;
        --h5: 32px;
        --h6: 24px;
        --h7: 20px;
        --h8: 16px;
        --p:16px;

         //grid 
        --containerPadding: var(--sp15x);
        --containerPaddingLarge: var(--sp7x);
        --containerPaddingHome:var(--sp10x);

        //section margin bottom
        --sectionMarginBottom:var(--sp20x);
        --sectionMarginBottomHome:var(--sp16x);

        --secToSecMarginHorizontal:var(--sp16x);
    }
}

@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
	props.theme.mediaQuery.DesktopSizeXS}) {
    :root {
        --h1: 160px;
        --h2: 104px;
        --h3: 64px;
        --h4: 40px;
        --h5: 32px;
        --h6: 20px;
        --h7: 20px;
        --h8: 16px;
        --p:16px;

        //grid 
        --containerPadding: var(--sp13x);
        --containerPaddingLarge: var(--sp4x);
        --containerPaddingHome:var(--sp7x);

        //section margin bottom
        --sectionMarginBottom:var(--sp18x);
        --sectionMarginBottomHome:var(--sp14x);

        --secToSecMarginHorizontal:var(--sp14x);
    }
}

@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) =>
	props.theme.mediaQuery.TabletSize}) {
    :root {
        --h1: 96px;
        --h2: 64px;
        --h3: 56px;
        --h4: 40px;
        --h5: 24px;
        --h6: 20px;
        --h7: 18px;
        --h8: 16px;
        --p:14px;

        //grid 
        --containerPadding: var(--sp7x);
        --containerPaddingLarge: var(--sp4x);
        --containerPaddingHome:var(--sp7x);

        //section margin bottom
        --sectionMarginBottom:var(--sp15x);
        --sectionMarginBottomHome:var(--sp14x);
    }
}

@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
    :root {
        --h1: 48px;
        --h2: 40px;
        --h3: 48px;
        --h4: 32px;
        --h5: 20px;
        --h6: 18px;
        --h7: 16px;
        --h8: 14px;
        --p:14px;
        
        //grid 
        --containerPadding: var(--sp3x);
        --containerPaddingLarge: var(--sp3x);
        --containerPaddingHome:var(--sp7x);

        //section margin bottom
        --sectionMarginBottom:var(--sp12x);
        --sectionMarginBottomHome:var(--sp14x);
    }
}
`

export default Variables
