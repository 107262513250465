import styled from 'styled-components'

const HeaderCartStyle = styled.div`
	justify-content: flex-end;
	align-self: flex-end;
	align-items: center;
	pointer-events: all;
	z-index: 100;
	margin-right: 4%;
	margin-left: auto;
	position: ${(props) => (props.isHome ? 'fixed' : 'absolute')};
	right: 0;
	top: 0;

	.bw-Basket {
		&:before {
			transition: var(--trTime2);
		}
	}

	.cartLink {
		p {
			transition: var(--trTime2);
		}
		&:hover {
			p {
				color: var(--darkGrey);
			}
		}
	}

	.basketLink {
		p {
			transition: var(--trTime2);
		}

		&:hover {
			p {
				color: var(--darkGrey);
			}
			.bw-Basket {
				&:before {
					color: var(--darkGrey);
				}
			}
		}
	}

	margin-top: var(--sp8x);
	&.blogOrShop {
		margin-top: 0;
	}
	${(props) => (props.isHome ? 'mix-blend-mode: difference' : '')};

	.itemCount {
		margin-left: var(--spSize);
	}

	.basketLink {
		margin: 0 var(--sp2x) 0 var(--sp4x);
		i {
			font-size: var(--sp6x);

			&.black {
				&:before {
					color: var(--black);
				}
			}
			&.white {
				&:before {
					color: var(--lightWhite);
				}
			}
		}
	}

	&.inHome {
		margin-right: 0;
		margin-top: 0;
		position: relative;
		top: unset;
		right: unset;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeM}) {
		margin-top: var(--sp6x);
		.basketLink {
			margin: 0 var(--sp1-5x) 0 var(--sp3x);
			i {
				font-size: var(--sp4-5x);
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeXS}) {
		margin-top: var(--sp6x);

		.basketLink {
			margin: 0 var(--sp1-5x) 0 var(--sp3x);
			i {
				font-size: var(--sp3-5x);
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.TabletSize}) {
		margin-top: var(--sp6x);

		.basketLink {
			margin: 0 var(--sp1-5x) 0 var(--sp3x);
			i {
				font-size: var(--sp3-5x);
			}
		}
		.cartLink {
			&:hover {
				p {
					color: unset;
				}
			}
		}

		.basketLink {
			&:hover {
				p {
					color: unset;
				}
				.bw-Basket {
					&:before {
						color: unset;
					}
				}
			}
		}
	}
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		.basketLink {
			i {
				font-size: var(--sp3-5x);
			}
		}
		.cartLink {
			&:hover {
				p {
					color: unset;
				}
			}
		}

		.basketLink {
			&:hover {
				p {
					color: unset;
				}
				.bw-Basket {
					&:before {
						color: unset;
					}
				}
			}
		}
	}
`

export default HeaderCartStyle
