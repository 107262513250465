import styled from 'styled-components'

const BackButtonStyle = styled.div`
	margin-top: var(--sp8x);

	.backButton {
		cursor: pointer;
		display: inline-flex;
		i {
			margin-right: var(--spSize);
			&:before {
				font-size: var(--sp4-5x);
			}
		}
	}
	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeM}) {
		margin-top: var(--sp6x);

		.backButton {
			i {
				&:before {
					font-size: var(--sp3-5x);
				}
			}
		}
	}
	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeS}) {
		margin-top: var(--sp6x);

		.backButton {
			i {
				&:before {
					font-size: var(--sp2-5x);
				}
			}
		}
	}
	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeXS}) {
		margin-top: var(--sp5x);
		.backButton {
			i {
				&:before {
					font-size: var(--sp2-5x);
				}
			}
		}
	}
	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.LaptopSize}) {
		margin-top: var(--sp3x);
		.backButton {
			i {
				&:before {
					font-size: var(--sp2-5x);
				}
			}
		}
	}
	@media screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.TabletSize}) {
		margin-top: var(--sp3x);
		.backButton {
			i {
				&:before {
					font-size: var(--sp2-5x);
				}
			}
		}
	}
	@media screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		margin-top: var(--sp4x);
		.backButton {
			i {
				&:before {
					font-size: var(--sp2-5x);
				}
			}
		}
	}
`

export default BackButtonStyle
