import React, { useState, useEffect } from 'react'
import Cookies from 'js-cookie'

//* Style
import CookiePopupStyle from './style'

//* Helpers
import config from 'helpers/_config'

//* Components
import Text from 'components/common/Text'
import CustomLink from 'components/common/CustomLink'

const CookiePopup = () => {
    const [isAccepted, setIsAccepted] = useState(false)

    useEffect(() => {
        setIsAccepted(Cookies.get('accepted') ? true : false)
    }, [])

    return !isAccepted ? (
        <CookiePopupStyle>
            <Text tag={'h5'} className={'grandItalic h6 title'} text={'cookie'} />
            <Text tag={'p'} className={'geoRegular cookieMessage'} text={'cookieMessage'} />
            <div className={'description'}>
                <Text tag={'span'} className={'geoRegular'} text={'cookieDesc'} />
                &ensp;
                <CustomLink url={config.routes.terms.path}>
                    <Text tag={'span'} className={'geoRegular cursorPointer underline'} text={'privacyPolicy'} />
                </CustomLink>
                <Text tag={'span'} className={'geoRegular'} text={' and '} />
                <CustomLink url={config.routes.dataProtection.path}>
                    <Text tag={'span'} className={'geoRegular cursorPointer underline'} text={'ProtectionPolicy'} />
                </CustomLink>
            </div>
            <div
                onClick={() => {
                    setIsAccepted(true)
                    Cookies.set('accepted', true)
                }}
                className='btnWrap FlexBox alignMiddle alignCenter cursorPointer'
            >
                <Text tag={'span'} className={'michroma h8 white'} text={'gotIt'} />
            </div>
        </CookiePopupStyle>
    ) : null
}

export default CookiePopup
