import api from './_api'
import config from './_config'
import getContext from './_getContext'
import formValidation from './_formValidation'


import { getData, getPageParams } from './_getData'
import { getUser, checkUserRedirections } from './_getUser'

import {
  queryStringToObject,
  objectToQueryString,
  routerToPathAndQuery,
  getParamsByKey,
  imagePathToWebP,
  isWebPSupport,
  stringToLetters,
  arrayGen,
  arrayGenChild,
  formatNumber,
  randomIntFromInterval,
  storeText,
  objectToFormData,
  asyncFunctionDecorator,
  getRequestCookies,
  getCookie,
  setCookie,
  removeCookie,
} from './_functions'

import {
  letterify,
  splitLines,
  wordCurvedAnim
} from './_gsapFunctions'

export {
  api,
  config,
  getContext,
  getData,
  getPageParams,
  getUser,
  checkUserRedirections,
  queryStringToObject,
  objectToQueryString,
  routerToPathAndQuery,
  getParamsByKey,
  imagePathToWebP,
  isWebPSupport,
  stringToLetters,
  arrayGen,
  arrayGenChild,
  formValidation,
  formatNumber,
  randomIntFromInterval,
  letterify,
  splitLines,
  wordCurvedAnim,
  storeText,
  objectToFormData,
  asyncFunctionDecorator,
  getRequestCookies,
  getCookie,
  setCookie,
  removeCookie,
}
