import { Component, createContext } from 'react'
import { withRouter } from 'next/router'

//* HOCs
import withUserContext from '../consumerHOC/UserConsumer'

//* Helpers
import { api, setCookie, getCookie, removeCookie, asyncFunctionDecorator } from 'helpers'


const CartContext = createContext(null)
export const CartConsumer = CartContext.Consumer

class CartProvider extends Component {

    state = {
        items_quantity: 0,
        subtotal: 0,
        total: 0,
        productIds: [],
        items: []
    }

    methods = {
        checkAdded: this.checkAdded.bind(this),
        getCart: this.getCart.bind(this),
        getGuestProducts: this.getGuestProducts.bind(this),
        addToCart: this.addToCart.bind(this),
        removeCart: this.removeCart.bind(this),
        checkout: this.checkout.bind(this),
        clearCart: this.clearCart.bind(this)
    }

    componentDidMount() {
        this.getCart()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.isLoggedIn !== prevProps.isLoggedIn || (prevProps.router.locale !== this.props.router.locale)) {
            this.getCart()
        }
    }


    checkAdded(id) {
        return !!this.state.items?.find(item => item.product.id === id)
    }

    async getCart() {
        if (this.props.isLoggedIn) {
            return await api.get('cart', this.props.router.locale).then(({ data }) => this.setResourceDecorator(data))
        } else {
            const cookieCart = getCookie('cart')
            const cart = cookieCart ? JSON.parse(cookieCart) : null

            if (cart) {
                this.setState({ items: cart }, () => {
                    this.calculateCart()
                })
            }
        }
    }

    getGuestProducts(ids) {
        if (!this.props.isLoggedIn && ids.length > 0) {
            api.get('cart-products', this.props.locale, { ids: ids }).then(({ data }) => {
                const cartData = data.map(prod => {
                    return {
                        quantity: this.state.items[this.state.items.findIndex(item => item.product.id === prod.id)].quantity,
                        product: { ...prod },
                    }
                })

                this.setState({
                    items: cartData
                }, () => {
                    this.calculateCart(true)
                })
            })
        }
    }


    addToCart(productId, quantity, price, update = false) {
        if (this.props.isLoggedIn) {
            this.customerAddToCart(productId, quantity)
        } else {
            this.guestAddToCart(productId, quantity, price, update)
        }
    }


    async removeCart(productId) {
        if (this.props.isLoggedIn) {
            return await api
                .post('delete-cart-item', { id: productId }, this.props.router.locale)
                .then(({ data }) => this.setResourceDecorator(data))
        } else {
            const items = [...this.state.items]
            const index = items.findIndex(i => i.product.id === productId)
            items.splice(index, 1)
            this.setState({ items }, () => {
                setCookie('cart', JSON.stringify(this.state.items))
                this.calculateCart()
            })
        }
    }


    guestAddToCart(productId, quantity, price, update) {
        const newItem = this.state.items.find(i => i.product.id === productId)

        let items = []

        if (!newItem) items = this.state.items.concat({
            quantity: quantity,
            product: {
                id: productId,
                price: price
            },
        })
        else items = this.state.items.map(item => item.product.id === productId ? Object.assign({}, item, {
            quantity: quantity,
            product: {
                ...item.product,
                price: price
            }
        }) : item)

        this.setState({ items: items }, () => {
            setCookie('cart', JSON.stringify(this.state.items))
            this.calculateCart(update)
        })
    }

    async customerAddToCart(productId, quantity) {
        return await api
            .post('add-to-cart', { product_id: productId, quantity: quantity }, this.props.router.locale)
            .then(res => this.setResourceDecorator(res.data))
    }

    calculateCart(update) {
        let total = 0, subtotal = 0, items_quantity = 0, productIds = []

        this.state.items.map(item => {
            productIds.push(item.product.id)
            total += item.product.price * item.quantity
            subtotal += item.product.price * item.quantity
            items_quantity += item.quantity
        })

        this.setState({
            total,
            subtotal,
            items_quantity
        })

        if (!update) {
            this.setState({
                productIds
            })
        }
    }

    setResourceDecorator(data, additional = {}) {
        this.setState({
            items_quantity: data.items_quantity,
            subtotal: data.subtotal,
            total: data.total,
            items: data.items,
            ...additional
        })
    }

    checkout(data) {
        let checkoutData = {
            ...data,
            items: this.state.items,
            subtotal: this.state.subtotal,
            total: this.state.total
        }


        if (this.props.isLoggedIn) {
            checkoutData['user_id'] = this.props.user.id
            checkoutData['email'] = this.props.user.email
        } else {
            checkoutData['user_id'] = null
        }

        if (checkoutData['shipping'] == undefined) {
            checkoutData['shipping'] = null
        }

        return asyncFunctionDecorator(() =>
            api.post('checkout', checkoutData, this.props.router.locale)
        )
    }

    clearCart() {
        this.setState({
            items_quantity: 0,
            subtotal: 0,
            total: 0,
            productIds: [],
            items: []
        })

        removeCookie('cart')
    }

    render() {
        return (
            <CartContext.Provider value={{ ...this.state, ...this.methods }}>
                {this.props.children}
            </CartContext.Provider>
        )
    }
}

export default withRouter(withUserContext(CartProvider, ['isLoggedIn', 'user']))