import React, { memo as Memo } from 'react'

//* Components
import CustomLink from 'components/common/CustomLink'
import Image from 'components/common/Image'

//* Styles
import LogoStyle from './style'
import { withDataContext } from 'context'

const Logo = Memo((props) => {
    return (
        <LogoStyle className={`Logo ${props.className} ${props.footer ? 'footerLogo' : ''}`} onClick={props.logoClickScroll}>
            <CustomLink className='FlexBox alignMiddle' url={'/'} content={<Image priority={true} layout='fill' src={'/logo.svg'} alt='logo' />} />
        </LogoStyle>
    )
})

export default withDataContext(Logo, ['logoClickScroll'])
