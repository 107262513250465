import React, { useRef, forwardRef, useImperativeHandle, useState } from 'react'
import { useRouter } from 'next/router'

//* HOC's
import { withCartContext, withUIContext } from 'context'

//* Helpers
import config from 'helpers/_config'

//* Components
import Text from 'components/common/Text'
import Image from 'components/common/Image'
import Icon from 'components/common/Icon'

//* Style
import SingleProductHomeStyle from './style'

const SingleProductHome = forwardRef((props, ref) => {
	//! Refs
	const refs = useRef()
	const interval = useRef({ int: null, ind: 0 })

	//! States
	const [chooseImg, setChooseImg] = useState(props.images?.[interval.current.ind])
	const [isTextShown, setIsTextShown] = useState(false)
	const [isClicked, setIsClicked] = useState(false)

	//! Router
	const router = useRouter()

	useImperativeHandle(ref, () => {
		return refs.current
	})

	const handleMouseEnter = () => {
		props.winWidth >= 1280 &&
			(interval.current.int = setInterval(() => {
				if (interval.current.ind < props.images.length - 1) {
					interval.current.ind += 1
					setChooseImg(props.images[interval.current.ind])
				} else {
					interval.current.ind = 0
					setChooseImg(props.images[interval.current.ind])
				}
			}, 500))
	}

	const handleMouseLeave = () => {
		interval.current.ind = 0
		clearInterval(interval.current.int)
	}

	const handleAddToCartClick = () => {
		setIsClicked(!isClicked)
		setIsTextShown(true)

		setTimeout(() => {
			setIsTextShown(false)
		}, 1500)
	}

	return (
		<SingleProductHomeStyle
			ref={refs}
			className={`FlexBoxColumn ${props.winWidth < 1280 && 'active'}`}
			onMouseLeave={handleMouseLeave}
			onMouseEnter={handleMouseEnter}
		>
			<div onClick={() => router.push(config.routes.singleProduct.path.replace(':slug', props.slug))}>
				<Text tag='h6' className='cat h6 grandRoman' text={props.category} />
				<Image objectFit={'contain'} src={chooseImg} alt={props.title} />
				<Text tag='h6' className='title h6 michroma' text={props.title} />
				<div className='bottom FlexBox spaceBetween alignMiddle'>
					<Text tag='h6' className='price h6 geoLight' text={props.price + `€`} />
					<div className='relativeWrapp FlexBox addToCartWrap'>
						<Icon
							className={isClicked || props.checkAdded(props.id) ? 'bw-success pointerEventNone' : 'bw-Basket'}
							onClick={(e) => {
								e.stopPropagation()
								props.addToCart(props.id, 1, props.price)
								handleAddToCartClick()
							}}
						/>
						{isTextShown ? <Text className='addText geoLight' text='added' /> : null}
					</div>
				</div>
			</div>
		</SingleProductHomeStyle>
	)
})

export default withCartContext(withUIContext(SingleProductHome, ['winWidth']), ['addToCart', 'checkAdded'])
