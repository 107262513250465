import styled from 'styled-components'

const PreloaderStyle = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 112;
	overflow: hidden;
	background-color: var(--black);

	.LottieWrapp {
		width: 100vw;
		height: 100vh;
		background-color: var(--black);
	}
`
export default PreloaderStyle
