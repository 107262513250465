import styled from 'styled-components'

const ButtonStyle = styled.div`
	--homeBtnIconMarginLeft: var(--sp7x);
	--homeBtnIconFontSize: var(--sp13x);
	--iconWidth: var(--sp7-5x);
	--iconMarginLeft: var(--sp4x);

	-webkit-appearance: none;
	cursor: pointer;
	display: inline-flex;
	justify-content: center;
	position: relative;
	z-index: 1;
	overflow: hidden;
	background-color: transparent;
	transition: var(--trTime);
	line-height: 1;

	svg {
		width: var(--sp7-5x);
		margin-left: var(--iconMarginLeft);
	}

	&.no-transition {
		transition: none;
	}

	&.homeBtn {
		align-items: center;
		color: var(--white);
		white-space: nowrap;
		overflow: visible;

		a {
			color: var(--white);
		}

		.LottieWrapp {
			width: var(--sp13x);
		}

		&.black {
			color: var(--black);
			fill: var(--black);

			a {
				color: var(--black);
			}

			g {
				fill: var(--black);
			}
			path {
				stroke: var(--black);
				fill: var(--black);
			}
		}
	}

	&.bigBtn {
		align-self: flex-start;
		align-items: baseline;

		.LottieWrapp {
			width: var(--sp34x);
		}

		i {
			margin-left: var(--sp6x);
			font-size: var(--sp6x);
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeM}) {
		--homeBtnIconMarginLeft: var(--sp5x);
		--homeBtnIconFontSize: var(--sp10x);
		--iconWidth: var(--sp7x);
		--iconMarginLeft: var(--sp3x);

		&.homeBtn {
			.LottieWrapp {
				width: var(--sp9-5x);
			}
		}

		&.bigBtn {
			.LottieWrapp {
				width: var(--sp26x);
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeS}) {
		--homeBtnIconMarginLeft: var(--sp4x);
		--homeBtnIconFontSize: var(--sp7-5x);
		--iconWidth: var(--sp6x);
		--iconMarginLeft: var(--sp3x);

		&.homeBtn {
			.LottieWrapp {
				width: var(--sp8-5x);
			}
		}
		&.bigBtn {
			.LottieWrapp {
				width: var(--sp18x);
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.LaptopSize}) {
		--homeBtnIconMarginLeft: var(--sp3x);
		--homeBtnIconFontSize: var(--sp7x);
		--iconWidth: var(--sp6x);
		--iconMarginLeft: var(--sp3x);

		&.homeBtn {
			.LottieWrapp {
				width: var(--sp8-5x);
			}
		}

		&.bigBtn {
			.LottieWrapp {
				width: var(--sp18x);
			}
			i {
				margin-left: var(--sp3x);
				font-size: var(--sp4x);
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.TabletSize}) {
		--homeBtnIconMarginLeft: var(--sp3x);
		--homeBtnIconFontSize: var(--sp6x);
		--iconWidth: var(--sp6x);
		--iconMarginLeft: var(--sp3x);

		&.homeBtn {
			.LottieWrapp {
				width: var(--sp7-5x);
			}
		}

		&.bigBtn {
			.LottieWrapp {
				width: var(--sp16x);
			}
			i {
				margin-left: var(--sp3x);
				font-size: var(--sp4x);
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		--homeBtnIconMarginLeft: var(--sp2-5x);
		--homeBtnIconFontSize: var(--sp5-5x);
		--iconWidth: var(--sp6x);
		--iconMarginLeft: var(--sp2x);
		&.homeBtn {
			.LottieWrapp {
				width: var(--sp6x);
			}
		}

		&.bigBtn {
			.LottieWrapp {
				width: var(--sp12x);
			}
			i {
				display: none;
			}
		}
	}
`
export default ButtonStyle
