import api from './_api'
import config from './_config'
import { getRequestCookies, getCookie } from './_functions'

export const getPageParams = (lang, router) => getRouteObjFromConfig(lang, router)

export async function getData({ lang, state, router, getPageParams = false, update = false }) {
	const accessToken = getRequestCookies(router, 'accessToken') || getCookie('accessToken')
	const pageParams = getRouteObjFromConfig({ lang, router })
	let newState = state
	let promiseObj = {}

	if (!state.global?.[lang]) {
		promiseObj.global = getGlobal(lang)
	}

	if (pageParams) {
		if (
			pageParams.name &&
			pageParams.slug &&
			!pageParams.singleSlug &&
			((!state.pages?.[pageParams.name]?.[lang] && pageParams.autoload) || update)
		) {
			promiseObj.pages = getPage(pageParams.slug, lang, {}, accessToken)
		}

		if (pageParams?.singleSlug && pageParams.autoload && router.query.slug && !state?.[pageParams.singleType]?.[router.query.slug]?.[lang]) {
			promiseObj.singleItem = getPage(`${pageParams.slug}/${router.query.slug}`, lang, {}, accessToken)
		}

		if (pageParams.postType && pageParams.postType.slug && !state?.[pageParams.postType.name]?.[pageParams.queryName]?.[lang]) {
			promiseObj.postType = getPostType(pageParams.postType.slug, lang, pageParams.postType.queryParams)
		}
	}

	const promises = Object.values(promiseObj)

	promises.length > 0 &&
		(await Promise.all(promises)
			.then((res) => {
				const keys = Object.keys(promiseObj)

				keys.forEach((el, k) => {
					const type = el === 'pages' ? 'name' : el === 'postType' ? 'queryName' : null

					newState = updateState({
						lang,
						stateKey: el === 'postType' ? pageParams.postType.name : el == 'singleItem' ? pageParams?.singleType : el,
						response: res[k],
						type: el === 'singleItem' ? router.query.slug : type && pageParams[type],
						state: newState,
					})
				})
			})
			.catch((err) => {
				console.error('Get Data Catch in Line 52')
				console.log(err, 'api error')
				if (typeof window === 'undefined' && router.res.writeHead) {
					router.res.writeHead(302, { Location: '/404' })
					router.res.end()
				}
			}))

	return getPageParams ? { ...newState, pageParams } : newState
}

const getGlobal = (lang) => {
	return api.get('global-data', lang).then((res) => {
		return res.content
	})
}

const getPage = (slug, lang, queryParams = {}, accessToken) => {
	return api.get(slug, lang, queryParams, accessToken)
}

const getPostType = (slug, lang, queryParams = {}) => {
	return api.get(`${slug}`, lang, queryParams).then((res) => {
		return res
	})
}

const getRouteObjFromConfig = ({ lang, router }) => {
	let path = router.asPath.split('?')[0]

	const regex = new RegExp(`^\/${lang}`, '')
	path = path.replace(regex, '')

	if (router.query.slug) {
		path = path.replace(router.query.slug + '/', '') + ':slug'
	} else if (router.query.params) {
		const qPLength = router.query.params.length
		path = path.replace(router.query.params[0] + '/', ':category/')

		if (qPLength === 2) {
			path = path.replace(router.query.params[1] + '/', '') + ':slug'
		}
	}

	const removedLastPath = path.substr(0, path.lastIndexOf('/'))

	let route = Object.values(config.routes).filter((r) => {
		if (r.path) {
			const pathArr = r.path.split(':')
			return (
				r.path === path ||
				r.path.replace(/\/$/, '') === path.replace(/\/$/, '') ||
				(pathArr[0].replace(/\/$/, '') === removedLastPath && pathArr[1] && r.autoload)
			)
		}
	})[0]

	route =
		router.query.slug || router.query?.params?.length === 2
			? {
					...route,
					singleSlug: true,
					selectedSlug: router.query.slug ? router.query.slug : router.query?.params?.length === 2 && router.query.params[1],
			  }
			: route

	//! For Query Params
	let queryParams = {}
	let queryName = 'default'

	if (route?.postType?.queryParamKeys) {
		for (const q of route.postType.queryParamKeys) {
			if (router.query[q]) {
				queryName = `${queryName !== 'default' ? queryName + '&' : ''}${q}=${router.query[q]}`
				queryParams[q] = router.query[q]
			}
		}

		route.postType.queryParams = queryParams
		route.queryName = queryName
	}

	return router.asPath === '404' ? false : route
}

export const updateState = ({ state, lang, stateKey, response, type }) => {
	return {
		...state,
		[stateKey]: type
			? {
					...state?.[stateKey],
					[type]: { ...state?.[stateKey]?.[type], [lang]: response },
			  }
			: { ...state[stateKey], [lang]: response },
	}
}
