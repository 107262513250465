import SplitText from "gsap/dist/SplitText";

import {
    gsap,
    TweenMax
} from "gsap";

gsap.registerPlugin(SplitText);

export const letterify = (text,delay) => {
    write(text);

    gsap.set(text, { opacity: 1 });

    function write(el) {

        let split = new SplitText(el);
        let tl = gsap.timeline();

        split.words.forEach(function(word, whiteSpace) {

            if (whiteSpace) {
                tl.set({}, {}, "+=" + 0.45)
            }
        });

        gsap.from(split.chars, {
            duration: 0.3,
            y: 200,
            stagger: 0.05,
            delay: delay
        });

        return tl;
    }
}

export const splitLines = (element,parentWidth) => {
    const splitted = new SplitText(element, {
        type: "lines",
        linesClass: "line++"
    });
    gsap.to(
        splitted.lines,
        0.75,
        {
            opacity:1,
            y: 0,
            ease: "power3.inOut",
            stagger:0.08,
        },
    );
}

export const wordCurvedAnim = (element) =>{
    let wordLength = element.length;
    let centerIndex = Math.floor(wordLength / 2);
    let isLengthEven = wordLength % 2 === 0;

    return Object.values(element).map((i,k) => {
        if(Number(k) === centerIndex || (Number(k) === centerIndex - 1 && isLengthEven)){
            i.classList.add('center')
            i.style.transition = `1.5s cubic-bezier(0.76, 0, 0.24, 1)`
        } else {
            let ind = k - centerIndex;
            i.classList.add(`ind(${ind})`)
            i.style.transform = `translate(${ind * 15}px,100px)`
            i.style.transition = `1.5s cubic-bezier(0.76, 0, 0.24, 1) ${Math.abs(ind) * 0.15}s`
        }
    })
}