import api from './_api'
import config from './_config'
import { getRequestCookies, getCookie } from './_functions'


export async function getUser(lang, state, ctx) {
    const accountParams = getRouteObjFromConfig(lang, ctx)
    const accessToken = getRequestCookies(ctx, 'accessToken') || getCookie('accessToken')
    const newState = state
    let user, redirect, redirectUrl


    if (!accessToken && accountParams) {
        redirect = true
    } else if (accessToken) {
        user = await getInfo(lang, accessToken, ctx.res)
        newState.user = user
    }

    if (!user && accountParams) {
        redirect = true
        newState.isLoggedIn = false
        newState.user = {}
    } else if (user) {
        newState.isLoggedIn = true
    }

    return { newState, redirect, redirectUrl }
}

const getInfo = (lang, accessToken, res) => {
    return api
        .get('account', lang, {}, accessToken)
        .then((res) => {
            return res.customer
        })
        .catch((err) => {
            if (process?.browser) {
                document.cookie = 'access_token=; expires=Thu, 01 Jan 1970 00:00:01 GMT;'
            } else {
                res.clearCookie('access_token')
            }
        })
}

export function checkUserRedirections({ user, ctx, params, lang, redirectAutomatically }) {
    const accountParams = params || getRouteObjFromConfig(lang, ctx)
    let redirectUrl

    if (!user && accountParams) {
        redirectUrl = '/sign-in'
    }


    if (process.browser && redirectUrl && redirectAutomatically) {
        ctx.push(redirectUrl)
    }

    return redirectUrl
}


const getRouteObjFromConfig = (lang, router) => {
    const privateRoutes = Object.values(config.routes).filter((r) => r?.private === true)

    let path = router.asPath.split('?')[0]

    const regex = new RegExp(`^\/${lang}`, '')
    path = path.replace(regex, '')

    const removedLastPath = path.substr(0, path.lastIndexOf('/'))

    let route = privateRoutes.filter((r) => {
        const pathArr = r.path.split(':')
        return (
            r.path === path ||
            r.path.replace(/\/$/, '') === path.replace(/\/$/, '') ||
            (pathArr[0].replace(/\/$/, '') === removedLastPath && pathArr[1] && r.autoload)
        )
    })[0]

    return router.asPath === '404' ? false : route
}