import { createGlobalStyle } from 'styled-components'

const HelperClass = createGlobalStyle`
    body {
        width: 100%;
        min-height: 100vh;
        margin: 0;
        padding: 0;
        color: var(--black);
        font-family: geoRegular;
        line-height: var(--lineHeight);
        -webkit-overflow-scrolling: touch;
        overflow-x: hidden;

        position: relative;
        background: var(--lightWhite);

        .min-width{
            min-width: 100vw;
            
        }

        @media only screen and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) {
            .max-height-100{
                height: 100vh;   
            }
        }


        .min-height{
            min-height: 100vh;   
        }

        main {
            z-index: 1;
            position: relative;
        }
        
        #__next {
            min-height: 100vh;
        }

        #root {
            overflow-x: hidden;
        }

        ::-webkit-scrollbar {
            display: none;
        }

        &.hidden {
            overflow:hidden;
            max-height:100vh;
        }
    }

    .pointerEventNone {
        pointer-events: none;
    }

    .modal-open {
        overflow-y: hidden;
    }

    input[type="text" i] {
        padding: 0px 0px;
    }

    input[type="search" i] {
        padding: 0px 0px;
    }

    ul,
    fieldset {
        margin: 0;
        padding: 0;
        list-style: none;
        border: none;
    }

    // flex box

    .FlexBoxContainer {
        display: flex;
        flex-wrap: wrap;
    }

    .FlexBoxColumn {
        display: flex;
        flex-direction: column;
    }

    .FlexBox {
        display: flex;
    }

    .alignMiddle {
        align-items: center;
    }

    .alignCenter {
        justify-content: center;
    }

    .justifyEnd {
        justify-content: flex-end;
    }
    
    .alignEnd{
        align-items: flex-end;
    }

    .rowReverse {
        flex-direction: row-reverse;
    }

    .spaceBetween {
        justify-content: space-between;
    }
    .spaceAround {
        justify-content: space-around;
    }


    .cursorPointer{
        cursor:pointer;
    }
    .cursorDefault{
        cursor:default;
    }

    .textAlignCenter {
        text-align:center;
    }

    .textAlignRight {
        text-align:right;
    }

    //!Colors

    //relaative

    .relativeWrapp{
        position: relative;
    }

    .overflowHidden{
        overflow:hidden;
    }

    //letter animations
    .animLetterSpacing {
        transition:0.6s;
        display: flex;
        
        &.animPlay {
            .word {
                transform: translateY(0) !important;
                opacity: 1;
            }
        }

        .word {
            display: inline-block;
            transition:0.6s cubic-bezier(0.76, 0, 0.24, 1);
            transform: translateY(100px);
            opacity: 0;
        }
    }

    //!Locomotive scroll

    html.has-scroll-smooth {
        overflow: hidden; 
    }

    html.has-scroll-dragging {
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none; 
    }

    .has-scroll-smooth body {
        overflow: hidden; 
    }

    .has-scroll-smooth [data-scroll-container] {
        min-height: 100vh; 
    }

    .mixBlendDifference {
        mix-blend-mode: difference;
    }
`

export default HelperClass
