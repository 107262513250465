import React, { Component, createContext } from 'react'

//* HOCs
import { withRouter } from 'next/router'

//* Helpers
import { ThemeProvider } from 'styled-components'
import themes from 'styles/theme'
import HelperClass from 'styles/helperClass'
import Variables from 'styles/variables'
import Typography from 'styles/typography'


const UIContext = createContext(null)

class UIProvider extends Component {
    //! States
    state = {
        winWidth: 1920,
        winHeight: 1080,
        screenSizes: {
            screen3XL: '',
            screen2XL: '',
            screenXL: '',
            screenL: '',
            screenM: '',
            screenS: '',
            screenXS: '',
        },
        preloaderHide: false,
        preloader: true,
        popupIsOpen: false,
        headerBlack: false,
        shopSmallWidth: 0,
        pageOffsetScrollLeft: 0,
        pageOffsetScrollLeftInPixels: 0,
        shopSmallSectionHeight: 0,
    }

    popups = ['login', 'register', 'forgot-password']

    //! Methods
    methods = {
        togglePopup: this.togglePopup.bind(this),
        disableDomScroll: this.disableDomScroll.bind(this),
        enableDomScroll: this.enableDomScroll.bind(this),
        makeHeaderWhite: this.makeHeaderWhite.bind(this),
        makeHeaderBlack: this.makeHeaderBlack.bind(this),
        getLoaderEnd: this.getLoaderEnd.bind(this),
        setShopSmallWidth: this.setShopSmallWidth.bind(this),
        getPageOffsetScroll: this.getPageOffsetScroll.bind(this),
        getShopSmallHeight: this.getShopSmallHeight.bind(this),
    }

    //! Screen Resize
    screenResize = () => {
        this.setState({
            winWidth: window.innerWidth,
            winHeight: window.innerHeight,
        })
    }

    //! Get Media Screen Sizes from Css Variables
    getScreenSizes() {
        const root = getComputedStyle(document.documentElement)

        const screenSizes = {
            screen3XL: parseInt(root.getPropertyValue('--screen3XL')),
            screen2XL: parseInt(root.getPropertyValue('--screen2XL')),
            screenXL: parseInt(root.getPropertyValue('--screenXL')),
            screenL: parseInt(root.getPropertyValue('--screenL')),
            screenM: parseInt(root.getPropertyValue('--screenM')),
            screenS: parseInt(root.getPropertyValue('--screenS')),
            screenXS: parseInt(root.getPropertyValue('--screenXS')),
        }

        this.setState({ screenSizes })
    }

    togglePopup() {
        this.setState({
            popupIsOpen: !this.state.popupIsOpen,
        })
    }

    getLoaderEnd() {
        setTimeout(() => {
            this.setState({ preloaderHide: true })

            setTimeout(() => {
                this.setState({ preloader: false })
            }, 1050)
        }, 2000)
    }

    setShopSmallWidth(width) {
        this.setState({
            shopSmallWidth: width,
        })
    }

    getPageOffsetScroll(scroll) {
        this.setState({
            pageOffsetScrollLeft: scroll,
            pageOffsetScrollLeftInPixels: (scroll * this.state.winWidth) / -100,
        })
    }

    makeHeaderBlack() {
        this.setState({
            headerBlack: true,
        })
    }

    makeHeaderWhite() {
        this.setState({
            headerBlack: false,
        })
    }

    getShopSmallHeight(height) {
        this.setState({
            shopSmallSectionHeight: height,
        })
    }

    //! Disable DOM Scroll
    disableDomScroll() {
        // document.querySelector('html').classList.add('hide-scroll')

        const scrollTop = window.pageYOffset || document.documentElement.scrollTop
        const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft

        // if any scroll is attempted, set this to the previous value
        window.onscroll = function (e) {
            e.preventDefault()
            window.scrollTo(scrollLeft, scrollTop)
        }
    }

    //! Enable DOM Scroll
    enableDomScroll() {
        // document.querySelector('html').classList.remove('hide-scroll');

        const scrollTop = window.pageYOffset || document.documentElement.scrollTop
        const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft

        window.onscroll = function () { }
        window.scrollTo(scrollLeft, scrollTop)
    }

    //! Component Did Mount
    componentDidMount() {
        this.setState({
            ...this.state,
        })

        window.addEventListener('resize', this.screenResize)

        this.getScreenSizes()
        this.screenResize()
        this.getLoaderEnd()
    }

    //! Component Will Unmount
    componentWillUnmount() {
        window.addEventListener('resize', this.screenResize)
        document.removeEventListener('keydown', this.escKeydown, false)
    }

    render() {
        return (
            <UIContext.Provider value={{ ...this.state, ...this.methods }}>
                <ThemeProvider theme={themes}>
                    <Variables />
                    <Typography />
                    <HelperClass />
                    {this.props.children}
                </ThemeProvider>
            </UIContext.Provider>
        )
    }
}

export default withRouter(UIProvider)
export const UIConsumer = UIContext.Consumer
