import styled from 'styled-components'

const PageStyle = styled.main.attrs((props) => ({}))`
	&.home {
        overflow-y: hidden;
        overflow-x: auto;
        width:100vw;
        background-color: var(--black);
        
        ::-webkit-scrollbar {
            display: none;
        }
    }
    @media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}){
        &.home {
            overflow-y: auto;
            overflow-x: auto;
            height: unset;
        }
    }
`
export default PageStyle
