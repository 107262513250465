export default {
    routes: {
        home: {
            path: '/',
            slug: 'home',
            name: 'home',
            autoload: true,
        },
        shop: {
            path: '/shop',
            slug: 'products',
            name: 'shop',
            headerMenu: true,
            autoload: true,
        },
        singleProduct: {
            path: '/shop/:slug',
            slug: 'products',
            name: 'shop',
            singleType: 'singleProduct',
            headerMenu: false,
            autoload: true,
        },
        blog: {
            path: '/blog',
            slug: 'blog',
            name: 'blog',
            headerMenu: true,
            autoload: false,
            postType: {
                slug: 'blog',
                name: 'posts',
                queryParamKeys: ['page'],
            },
        },
        singleBlog: {
            path: '/blog/:slug',
            slug: 'blog',
            name: 'blog',
            singleType: 'singleBlog',
            headerMenu: true,
            autoload: true,
        },
        terms: {
            path: '/terms',
            slug: 'terms',
            name: 'terms',
            headerMenu: false,
            autoload: true,
        },
        dataProtection: {
            path: '/data-protection',
            slug: 'policy',
            name: 'data-protection',
            headerMenu: false,
            autoload: true,
        },
        signIn: {
            path: '/sign-in',
            slug: 'sign-in',
            name: 'signIn',
            headerMenu: false,
            autoload: false,
        },
        signUp: {
            path: '/sign-up',
            slug: 'sign-up',
            name: 'signUp',
            headerMenu: false,
            autoload: false,
        },
        forgotPassword: {
            path: '/forgot-password',
            slug: 'forgot-password',
            name: 'forgotPassword',
            headerMenu: false,
            autoload: false,
        },
        resetPassword: {
            path: '/reset-password',
            slug: 'reset-password',
            name: 'resetPassword',
            headerMenu: false,
            autoload: false,
        },
        account: {
            path: '/account',
            slug: 'account',
            name: 'account',
            headerMenu: false,
            autoload: true,
            private: true,
        },
        cart: {
            path: '/cart',
            slug: 'cart',
            name: 'cart',
            headerMenu: false,
            autoload: false,
        },
        checkout: {
            path: '/checkout',
            slug: 'checkout',
            name: 'checkout',
            headerMenu: false,
            autoload: false,
        },
    },
}
