import React, { useCallback, useState } from 'react'
import { useRouter } from 'next/router'

//* HOC's
import { withUIContext } from 'context'

//* Helpers
import { config } from 'helpers'

//* Components
import Logo from 'components/common/Logo'
import LanguageSwitcher from 'components/common/LanguageSwitcher'
import CustomLink from 'components/common/CustomLink'
import Text from 'components/common/Text'
import HeaderCart from 'components/common/HeaderCart'
import LottieAnimation from 'components/common/LottieAnimation'

//* Style
import Header from './style'

//* Lottie
import burgerLottie from 'lottieAnimation/burgerLottie.json'

const AppHeader = ({ winWidth, headerBlack, shopSmallSectionHeight, shopSmallWidth, pageOffsetScrollLeft }) => {
    //! States
    const router = useRouter()
    const [isOpen, setIsOpen] = useState(false)
    const [lottiePlatState, setLottiePlatState] = useState(false)

    const storeStyle = useCallback(() => {
        return {
            transform:
                router.pathname === '/' && pageOffsetScrollLeft < shopSmallWidth && winWidth >= 1280
                    ? `translateX(-${pageOffsetScrollLeft}px)`
                    : `translateX(0vw)`,
            left: router.pathname === '/' && winWidth >= 1280 ? shopSmallWidth : 0,
            top: router.pathname === '/' && winWidth >= 1280 ? 0 : shopSmallSectionHeight,
            background: router.pathname === '/' ? '' : 'var(--lightWhite)',
        }
    }, [router.pathname, pageOffsetScrollLeft, shopSmallWidth, winWidth, shopSmallSectionHeight])

    const headerPos = pageOffsetScrollLeft > shopSmallWidth && router.pathname === '/' ? 'fixed' : ''
    const isHome = router.pathname === '/' && 'inHome'
    const isHeaderBlack = headerBlack ? 'black' : ''

    return (
        <Header
            langColor={router.pathname === '/' ? '' : 'black'}
            style={storeStyle()}
            className={`header ${winWidth < 1280 ? '' : 'FlexBoxColumn'} spaceBetween alignMiddle ${headerPos} ${isHome} ${isHeaderBlack}`}
        >
            <Logo />
            {winWidth > 767 ? (
                <div className='bottom FlexBox alignMiddle'>
                    <div className='routes FlexBox alignMiddle'>
                        <div className='FlexBox'>
                            <CustomLink className='headerLink' url={config.routes.shop.path}>
                                <Text
                                    tag='p'
                                    className={`${router.pathname === '/shop' ? 'geoSemiBold' : 'geoReg'} blog uppercase h7 ${router.pathname === '/' ? '' : 'black'
                                        }`}
                                    text={'productsHeader'}
                                />
                            </CustomLink>
                            <CustomLink className='headerLink' url={config.routes.blog.path}>
                                <Text
                                    tag='p'
                                    className={`${router.pathname === '/blog' ? 'geoSemiBold' : 'geoReg'} uppercase h7 ${router.pathname === '/' ? '' : 'black'
                                        }`}
                                    text={'blog'}
                                />
                            </CustomLink>
                        </div>
                        <LanguageSwitcher className='lang' />
                    </div>
                </div>
            ) : (
                <div className={`collapse ${isOpen ? 'headerOpened' : ''}`}>
                    <div className='routes FlexBox alignMiddle'>
                        <div className='FlexBox'>
                            <CustomLink url={config.routes.shop.path}>
                                <Text
                                    tag='p'
                                    className={`${router.pathname === '/shop' ? 'geoSemiBold' : 'geoReg'} uppercase h7 ${router.pathname === '/' ? '' : 'black'
                                        }`}
                                    text={'shop'}
                                />
                            </CustomLink>
                            <CustomLink url={config.routes.blog.path}>
                                <Text
                                    tag='p'
                                    className={`${router.pathname === '/blog' ? 'geoSemiBold' : 'geoReg'} uppercase blog h7 ${router.pathname === '/' ? '' : 'black'
                                        }`}
                                    text={'blog'}
                                />
                            </CustomLink>
                        </div>
                        <LanguageSwitcher className='lang' />
                    </div>
                </div>
            )}
            {winWidth < 1280 && <HeaderCart inHome />}
            {winWidth < 768 && (
                <div
                    onClick={() => {
                        setLottiePlatState(!lottiePlatState)
                        setIsOpen(!isOpen)
                    }}
                    className='lottieMainWrap FlexBox alignMiddle alignCenter'
                >
                    <LottieAnimation
                        animData={burgerLottie}
                        autoplay={false}
                        isPlay={!lottiePlatState}
                        isReverse={lottiePlatState}
                        loop={false}
                        className='FlexBox alignMiddle alignCenter'
                    />
                </div>
            )}
        </Header>
    )
}

export default withUIContext(AppHeader, [
    'screenSizes',
    'winWidth',
    'headerBlack',
    'shopSmallWidth',
    'pageOffsetScrollLeft',
    'shopSmallSectionHeight',
])
