import styled from 'styled-components'

const AnimatedArrowButtonStyle = styled.div`
	--iconSize: var(--sp3-5x);
	--circleSize: var(--sp9x);
	--circleMarginLeft: var(--sp4x);

	--marginTopAndBottom: ${(props) => (props.isBlog === 'blog' ? '0 0 0 0' : 'var(--sp13x) 0 var(--sp25x) 0')};

	margin: var(--marginTopAndBottom);

	&.hideBtn {
		visibility: hidden;
	}

	.buttonWrap {
		display: inline-flex;
		align-self: flex-end;
		&.rowReverse {
			.circle {
				margin-right: var(--circleMarginLeft);
			}
		}
		.circle {
			border: 1px solid var(--black);
			border-radius: 50%;
			width: var(--circleSize);
			height: var(--circleSize);
			margin-left: var(--circleMarginLeft);

			&.reversed {
				transform: rotate(180deg);
			}

			i {
				&:before {
					font-size: var(--iconSize);
				}
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeM}) {
		--iconSize: var(--sp3x);
		--circleSize: var(--sp7x);
		--circleMarginLeft: var(--sp3x);
		--marginTopAndBottom: ${(props) => (props.isBlog === 'blog' ? '0 0 0 0' : 'var(--sp12x) 0 var(--sp22-5x) 0')};
	}
	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeS}) {
		--iconSize: var(--sp2x);
		--circleSize: var(--sp5x);
		--circleMarginLeft: var(--sp2x);
		--marginTopAndBottom: ${(props) => (props.isBlog === 'blog' ? '0 0 0 0' : 'var(--sp10x) 0 var(--sp20x) 0')};
	}
	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeXS}) {
		--iconSize: var(--sp2x);
		--circleSize: var(--sp5x);
		--circleMarginLeft: var(--sp2x);
		--marginTopAndBottom: ${(props) => (props.isBlog === 'blog' ? '0 0 0 0' : 'var(--sp9x) 0 var(--sp17-5x) 0')};
	}
	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.LaptopSize}) {
		--iconSize: var(--sp2x);
		--circleSize: var(--sp5x);
		--circleMarginLeft: var(--sp2x);
		--marginTopAndBottom: ${(props) => (props.isBlog === 'blog' ? '0 0 0 0' : 'var(--sp7x) 0 var(--sp15x) 0')};
	}
	@media screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.TabletSize}) {
		--iconSize: var(--sp2x);
		--circleSize: var(--sp5x);
		--circleMarginLeft: var(--sp2x);
		--marginTopAndBottom: ${(props) => (props.isBlog === 'blog' ? '0 0 0 0' : 'var(--sp7x) 0 var(--sp15x) 0')};
	}
	@media screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		--iconSize: var(--sp2x);
		--circleSize: var(--sp5x);
		--circleMarginLeft: var(--spSize);
		--marginTopAndBottom: ${(props) => (props.isBlog === 'blog' ? '0 0 0 0' : 'var(--sp6x) 0 var(--sp12-5x) 0')};
	}
`

export default AnimatedArrowButtonStyle
