import styled from 'styled-components'

const TitleStyle = styled.div`
	&.Title {
		--titleMargin: var(--sp4x);
		--iconSize: var(--sp15x);
		--iconMarg: var(--sp6x);

		margin-bottom: var(--titleMargin);

		> div {
			width: 100%;
			&:first-child {
				justify-content: flex-start;
			}

			span {
				white-space: nowrap;
				transform: rotateY(-90deg) translateY(140%);
			}
		}

		&.alignReverse {
			> div {
				&:first-child {
					justify-content: flex-end;
				}
			}
		}

		.iconWrapp {
			transform: scale(0);
			i {
				display: inline-block;
				line-height: var(--lineHeightS);
				margin: 0 var(--iconMarg);
				font-size: var(--iconSize);
				transform-origin: center;
				animation: ease-custom 3000ms linear infinite;
			}
		}

		@keyframes ease-custom {
			0% {
				transform: rotate(-25deg);
			}
			38% {
				transform: rotate(28.2deg);
			}
			60% {
				transform: rotate(-13.8deg);
			}
			86% {
				transform: rotate(43.6deg);
			}
			100% {
				transform: rotate(-25deg);
			}
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
				props.theme.mediaQuery.DesktopSizeM}) {
			--titleMargin: var(--sp3x);
			--iconSize: var(--sp11-5x);
			--iconMarg: var(--sp5x);
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
				props.theme.mediaQuery.DesktopSizeS}) {
			--titleMargin: var(--sp3x);
			--iconSize: var(--sp9x);
			--iconMarg: var(--sp3x);
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
				props.theme.mediaQuery.DesktopSizeXS}) {
			--titleMargin: var(--sp2x);
			--iconSize: var(--sp9x);
			--iconMarg: var(--sp3x);
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) =>
				props.theme.mediaQuery.TabletSize}) {
			--titleMargin: var(--sp2x);
			--iconSize: var(--sp7x);
			--iconMarg: var(--sp2x);
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
			--titleMargin: var(--sp2x);
			--iconSize: var(--sp4-5x);
			--iconMarg: var(--sp2x);
		}
	}
`

export default TitleStyle
