export default {
	colors: {
		black: '#201f1f',
		white: '#fff',
		lightWhite: '#efefef',
		grey: '#a5a5a5',
		lightGrey: '#aaaaaa',
		darkGrey: '#8A8587',
		ultraDarkGrey: '#535457',
		errColor: '#ff0000',
	},

	mediaQuery: {
		DesktopSizeL: '2560px',
		DesktopSizeM: '1920px',
		DesktopSizeS: '1440px',
		DesktopSizeXS: '1280px',
		LaptopSize: '1024px',
		TabletSize: '768px',
		MobileSize: '601px',

		DesktopSizeLMin: '2559px',
		DesktopSizeMMin: '1919px',
		DesktopSizeSMin: '1439px',
		DesktopSizeXSMin: '1279px',
		LaptopSizeMin: '1023px',
		TabletSizeMin: '767px',
		MobileSizeMin: '600px',
	},
}
