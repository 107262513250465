import styled from 'styled-components'

const SingleProductHomeStyle = styled.div`
	padding: var(--sp7x);
	min-width: var(--sp94x);
	max-height: var(--sp117x);
	width: 100%;
	margin: 0 var(--sp7x);
	position: relative;
	transition: 0.5s cubic-bezier(0.76, 0, 0.24, 1);
	transform: translateX(30%);
	cursor: pointer;

	.addToCartWrap {
		flex-direction: row-reverse;
		align-items: center;
	}

	.addText {
		margin-right: var(--sp3x);
	}

	&:hover {
		background: var(--white);

		.bottom {
			i {
				border-radius: 50%;
				border: 1px solid var(--black);
			}
		}
	}

	&.active {
		transform: translateX(0);
	}

	&:before {
		content: '';
		position: absolute;
		top: 0;
		right: calc(100% + var(--sp7x));
		width: 1px;
		height: 100%;
		background-color: var(--lightGrey);
	}

	&:last-child {
		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: calc(100% + var(--sp7x));
			width: 1px;
			height: 100%;
			background-color: var(--lightGrey);
		}
	}

	.imageCont {
		width: 100%;
		padding-top: 78%;
		margin: var(--sp5x) 0 var(--sp9x);
		background: var(--lightWhite);

		/* & > * {
			object-fit: contain;
		} */
	}

	.bottom {
		margin-top: var(--sp2x);

		i {
			display: flex;
			justify-content: center;
			align-items: center;
			width: var(--sp8x);
			height: var(--sp8x);
			&.bw-success {
				font-size: var(--sp3-5x);
			}
			border: 1px solid transparent;
			&:hover {
				background: var(--black);
				border: 1px solid transparent;
				&:before {
					color: var(--lightWhite);
				}
			}

			font-size: var(--sp5x);
			padding: var(--sp2x) var(--sp2-5x);
			border-radius: 50%;
			background-color: var(--white);
			:before {
				color: var(--black);
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeM}) {
		padding: var(--sp5x);
		min-width: var(--sp70x);
		max-height: var(--sp87x);
		margin: 0 var(--sp5x);

		&:before {
			right: calc(100% + var(--sp5x));
		}

		&:last-child {
			&:after {
				left: calc(100% + var(--sp5x));
			}
		}

		.imageCont {
			padding-top: 75%;
			margin: var(--sp4x) 0 var(--sp7x);
		}

		.bottom {
			margin-top: var(--sp2x);

			i {
				font-size: var(--sp3x);
				padding: var(--sp1-5x) 15px;

				width: var(--sp6x);
				height: var(--sp6x);
				&.bw-success {
					font-size: var(--sp2-5x);
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeS}) {
		padding: var(--sp4x);
		min-width: var(--sp55x);
		max-height: var(--sp69x);
		margin: 0 var(--sp4x);

        .addText {
			margin-right: var(--sp2x);
		}

		&:before {
			right: calc(100% + var(--sp4x));
		}

		&:last-child {
			&:after {
				left: calc(100% + var(--sp4x));
			}
		}

		.imageCont {
			padding-top: 75%;
			margin: var(--sp2x) 0 var(--sp5x);
		}

		.bottom {
			margin-top: var(--sp2x);

			i {
				font-size: var(--sp3x);
				padding: var(--spSize) 11px;
				width: var(--sp6x);
				height: var(--sp6x);
				&.bw-success {
					font-size: var(--sp2-5x);
				}
			}
		}
	}
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeXS}) {
		padding: var(--sp3x);
		min-width: var(--sp54x);
		max-height: var(--sp66x);
		margin: 0 var(--sp3x);

        .addText {
			margin-right: var(--sp2x);
		}

		&:before {
			right: calc(100% + var(--sp3x));
		}

		&:last-child {
			&:after {
				left: calc(100% + var(--sp3x));
			}
		}

		.imageCont {
			padding-top: 75%;
			margin: var(--sp2x) 0 var(--sp5x);
		}

		.bottom {
			margin-top: var(--sp2x);

			i {
				font-size: var(--sp3x);
				padding: var(--spSize) 11px;
				width: var(--sp5x);
				height: var(--sp5x);
				&.bw-success {
					font-size: var(--sp2x);
				}
			}
		}
	}
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.TabletSize}) {
		padding: var(--sp3x);
		min-width: unset;
		max-height: unset;
		margin: 0;
		width: calc(50% - var(--sp4x));

        .addText {
			margin-right: var(--sp2x);
		}

		&:hover {
			background: transparent;

			.bottom {
				i {
					border-radius: 50%;
					border: 1px solid transparent;
				}
			}
		}

		.bottom {
			margin-top: var(--sp2x);

			i {
				&:hover {
					background: var(--white);
					border: 1px solid transparent;
					&:before {
						color: var(--black);
					}
				}
			}
		}

		&:nth-child(2n) {
			margin-left: var(--sp4x);
		}

		&:nth-child(2n-1) {
			margin-right: var(--sp4x);

			&:before {
				display: none;
			}
		}

		&:not(:first-child):not(:nth-child(2)) {
			margin-top: var(--sp3x);
		}

		&:before {
			right: calc(100% + var(--sp4x));
		}

		&:last-child {
			&:after {
				display: none;
			}
		}

		.imageCont {
			padding-top: 75%;
			margin: var(--sp2x) 0 var(--sp5x);
		}

		.bottom {
			margin-top: var(--sp2x);

			i {
				font-size: var(--sp3x);
				padding: var(--spSize) 11px;
				width: var(--sp5x);
				height: var(--sp5x);
				&.bw-success {
					font-size: var(--sp2x);
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		padding: var(--sp3x);
		min-width: unset;
		max-height: unset;
		margin: 0;
		width: 100%;

        .addText {
			margin-right: var(--sp2x);
		}

		&:hover {
			background: transparent;

			.bottom {
				i {
					border-radius: 50%;
					border: 1px solid transparent;
				}
			}
		}

		.bottom {
			margin-top: var(--sp2x);

			i {
				&:hover {
					background: var(--white);
					border: 1px solid transparent;
					&:before {
						color: var(--black);
					}
				}
			}
		}

		&:not(:first-child) {
			margin: var(--sp3x) 0;
		}

		&:before {
			right: 0;
			width: 100%;
			height: 1px;
			top: -24px;
		}

		&:last-child {
			margin-bottom: var(--sp3x);

			&:after {
				right: 0;
				width: 100%;
				height: 1px;
				bottom: -24px;
				top: unset;
				left: 0;
			}
		}

		.imageCont {
			padding-top: 75%;
			margin: var(--sp2x) 0 var(--sp5x);
		}

		.bottom {
			margin-top: var(--sp2x);

			i {
				font-size: var(--sp3x);
				padding: var(--spSize) 11px;
				width: var(--sp5x);
				height: var(--sp5x);
				&.bw-success {
					font-size: var(--sp2x);
				}
			}
		}
	}
`

export default SingleProductHomeStyle
