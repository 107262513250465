import React, { memo as Memo, useMemo } from 'react'
import { useRouter } from 'next/router'
import Icon from 'components/common/Icon'

const ShareLink = Memo((props) => {
    const copyUrl = () => {
        const el = document.createElement('input')
        el.value = window.location.href
        document.body.appendChild(el)
        el.select()
        document.execCommand('copy')
        document.body.removeChild(el)
    }

    const router = useRouter()
    const url = useMemo(() => process.env.host + router.asPath, [router])
    const facebookUrl = useMemo(() => `https://www.facebook.com/sharer/sharer.php?u=${url}`, [router])
    const twitterUrl = useMemo(() => `https://twitter.com/intent/tweet?url=${url}`, [router])
    const linkedinUrl = useMemo(() => `https://www.linkedin.com/shareArticle?mini=true&url=${url}`, [router])

    return (
        <>
            <a target='_blank' rel='noopener noreferrer' href={twitterUrl}>
                <Icon className='bw-twitter' />
            </a>
            <a target='_blank' rel='noopener noreferrer' href={facebookUrl}>
                <Icon className='bw-facebook' />
            </a>
            <a target='_blank' rel='noopener noreferrer' href={linkedinUrl}>
                <Icon className='bw-linkedin' />
            </a>
            <a rel='noopener noreferrer' onClick={copyUrl}>
                <Icon className='bw-save_link_icon cursorPointer' />
            </a>
        </>
    )
})

export default ShareLink
